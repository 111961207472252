export const cookiesPolicyData = [
  {
    id: 1,
    heading: "What Are Cookies?",
    detail: `Cookies are small text files that are placed on your device (computer, smartphone, or other electronic devices) when you visit a website. They are widely used to make websites work more efficiently, as well as to provide information to the website owners.`,
  },
  {
    id: 2,
    heading: "How We Use Cookies",
    detail: `We use cookies to enhance your browsing experience and provide personalised services. Cookies help us to:`,
    points: [
      {
        detail: "Understand and save your preferences for future visits.",
      },
      {
        detail: "Keep track of advertisements.",
      },
      {
        detail:
          "Compile aggregate data about site traffic and site interactions to offer better site experiences and tools in the future.",
      },
    ],
  },
  {
    id: 3,
    heading: "Types of Cookies Used",
    points: [
      {
        title: "Essential Cookies:",
        detail:
          "These cookies are necessary for the website to function properly. They enable core functionality such as security, network management, and accessibility.",
      },
      {
        title: "Analytical Cookies:",
        detail:
          "These cookies allow us to recognise and count the number of visitors to our website and analyze how visitors navigate the site. This helps us improve the website's performance.",
      },
      {
        title: "Functionality Cookies:",
        detail:
          "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third-party providers whose services we have added to our pages.",
      },
      {
        title: "Targeting Cookies:",
        detail:
          "These cookies collect information about your browsing habits to make advertising relevant to you and your interests.",
      },
    ],
  },
  {
    id: 4,
    heading: "Managing Cookies",
    detail: `You can manage or delete cookies as you wish. Most web browsers allow control over the use of cookies through their settings. You can set your browser to block cookies or to alert you when cookies are being sent. However, please note that if you disable cookies, some parts of our website may not function properly.`,
  },
  {
    id: 5,
    heading: "Third-Party Cookies",
    detail: `We may also use third-party cookies from service providers to help us analyse website usage and improve our services. These cookies are governed by the third-party providers’ privacy policies.`,
  },
  {
    id: 6,
    heading: "Changes to This Cookies Policy",
    detail: `We may update our Cookies Policy from time to time. We will notify you of any changes by posting the new Cookies Policy on this page. Please review this policy periodically for any updates.`,
  },
];
