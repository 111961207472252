import React from "react";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/footer";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import ScrollToTop from "../../hooks/ScrollToTop";
import { privacyPolicyData } from "./PrivacyPolicy.data";

const PrivacyPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <NavBar />
      <Container>
        <Grid container justifyContent={"center"}>
          <Card
            sx={{
              width: "100%",
              background: "#F0F2FC",
              p: { md: "50px", xs: "10px" },
              borderRadius: "15px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              mb: "40px",
            }}
          >
            <Typography
              className={`item-center font-size-on-small`}
              sx={{
                fontSize: "48px",
                fontWeight: "700",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
                textAlign: "center",
                pb: "20px",
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              textAlign="center"
              fontFamily="Red Hat Display"
              fontSize={16}
            >
              Compliance sheet is operated by <b>Pay io Services LTD </b>("We").
              We are registered in England and Wales under company number
              15439659 with its registered office at International House, 36-38
              Cornhill, London, England, EC3V 3NG, United Kingdom.
              <br />
              <br />
              This Privacy Policy explains how Compliance Sheet ("we," "our," or
              "us") collects, uses, and discloses your personal information when
              you visit and use the Compliance Sheet website (the "Website") in
              accordance with the UK General Data Protection
            </Typography>
            <Box height={"100%"} width={"100%"} padding={"20px 0"}>
              {privacyPolicyData?.map((item) => {
                return (
                  <Box key={item?.id}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Red Hat Display",
                        color: "#565666",
                        py: "10px",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        fontFamily: "Red Hat Display",
                        color: "#565666",
                        mb: "10px",
                      }}
                    >
                      {item.detail}
                    </Typography>
                    {item?.points &&
                      item?.points?.map((point, index) => (
                        <Typography
                          key={index}
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            fontFamily: "Red Hat Display",
                            color: "#565666",
                            mb: "10px",
                          }}
                          component="li"
                        >
                          {point?.title}{" "}
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                            }}
                            component={"span"}
                          >
                            {point?.detail}
                          </Typography>
                        </Typography>
                      ))}
                    {item?.id === 9 && (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          fontFamily: "Red Hat Display",
                          color: "#565666",
                          mb: "10px",
                        }}
                      >
                        This policy was last updated on May 01, 2024.
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Card>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
