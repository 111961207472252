import { Typography, Box, Grid } from "@mui/material";
import { productDetailData } from "./ProductDetails.data";
import { CheckboxCheckedIcon } from "../../../assests/CheckboxCheckedIcon";

export const ProductDetails = () => {
  return (
    <Box mb={10}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "1rem",
        }}
      >
        <Typography
          className="item-center font-size-on-small"
          sx={{
            width: { md: "40%", xs: "100%" },
            fontSize: "48px",
            fontWeight: "700",
            fontFamily: "Red Hat Display",
            color: "#565666",
            mb: 2,
            py: { md: "2rem" },
            textAlign: "center",
          }}
        >
          Products and Features
        </Typography>
      </Box>
      {productDetailData?.map((item) => (
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          spacing={5}
          key={item?.id}
          sx={{ transform: { lg: item?.transform }, mt: "1.5rem", px: "10px" }}
        >
          <Grid item xl={4.5} lg={5.5} xs={12} sx={{ transform: { lg: item.transform } }}>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
                mb: 2,
              }}
            >
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Red Hat Display",
                color: "#565666",
                mb: 2,
              }}
            >
              {item?.overview}
            </Typography>
            <Typography
              mb={1}
              fontWeight="bold"
              fontSize="25px"
              color="#565666"
              fontFamily="Red Hat Display"
            >
              Features
            </Typography>
            {item?.keyFeatures?.map((keys, index) => (

              <Box key={index} sx={{ display: "flex", alignItems: "center", mb: 1.5, gap: 1 }}>
                <CheckboxCheckedIcon />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                }}
              >
                {keys}
              </Typography>
            </Box>
            ))}
          </Grid>
          <Grid item xl={4.5} lg={5.5} xs={12} sx={{ p: { md: "20px" } }}>
            <img src={item.img} width={"100%"} alt={`Product ${item.id}`} style={{ borderRadius: '10px'}} />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};
