import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Checkbox,
} from "@mui/material";
import CustomizedSwitch from "../../../components/switch/Switch";
import { pricingCardData } from "./DataPricing";
import { CheckboxCheckedIcon } from "../../../assests/CheckboxCheckedIcon";
import { CheckboxIcon } from "../../../assests/CheckboxIcon";

const Cards = () => {
  const [toggle, setToggle] = useState({});
  const navigate = useNavigate();

  const handleToggle = (index, i) => {
    setToggle((prevState) => ({
      ...prevState,
      [`${i}-${index}`]: !prevState[`${i}-${index}`],
    }));
  };

  return (
    <>
      <Grid
        container
        justifyContent={"center"}
        mt={{ lg: "5rem", md: "4rem", xs: "2rem" }}
        spacing={2}
      >
        {pricingCardData?.map((section, i) => (
          <Grid
            item
            key={i}
            lg={4}
            md={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: { xl: "430px", md: "340px", sm: "448px" },
                background: "#BDC3FE",
                borderRadius: 4,
                border: "1px solid #F0F0F2",
                mb: "10px",
                p: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  fontFamily="Red Hat Display"
                  fontWeight={700}
                  align="center"
                  color="#565666"
                  gutterBottom
                >
                  {section?.title}
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontFamily="Red Hat Display"
                  fontWeight={500}
                  align="center"
                  color="#565666"
                  gutterBottom
                >
                  {section?.description}
                </Typography>
                <Box
                  p={2}
                  border="1px solid #fff"
                  borderRadius={2}
                  mb={2}
                  fontFamily="Red Hat Display"
                >
                  {section?.users}
                </Box>
                <Grid
                  container
                  spacing={3}
                  justifyContent="center"
                  flexDirection="column"
                >
                  {section?.detail?.map((detail, index) => (
                    <Grid item xs={12} key={index}>
                      <Card
                        sx={{
                          boxShadow: "none",
                          border: "1px solid #D3D3D3",
                          borderRadius: 2,
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mb: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {i !== 0 && (
                                <CustomizedSwitch
                                  sx={{ mb: 0.6 }}
                                  onClick={() => handleToggle(index, i)}
                                  checked={toggle[`${i}-${index}`] || false}
                                />
                              )}
                              <Typography
                                variant="subtitle2"
                                fontFamily="Red Hat Display"
                                fontWeight={700}
                                gutterBottom
                              >
                                {detail?.title}
                              </Typography>
                            </Box>
                            <Typography
                              variant="subtitle2"
                              fontFamily="Red Hat Display"
                              mb={0.6}
                            >
                              {detail?.amount}
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            {detail?.items?.map((item, idx) => (
                              <Grid item xs={12} key={idx} alignItems="center">
                                <Checkbox
                                  checked={
                                    i === 0 || toggle[`${i}-${index}`] || false
                                  }
                                  icon={<CheckboxIcon />}
                                  checkedIcon={<CheckboxCheckedIcon />}
                                  disabled={i !== 0}
                                />
                                <Typography
                                  variant="body2"
                                  component="span"
                                  fontFamily="Red Hat Display"
                                >
                                  {item}
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Button
                fullWidth
                variant="contained"
                size="large"
                sx={{
                  mt: 2,
                  bgcolor: "#697AE2",
                  "&:hover": {
                    background: "#5567d4",
                  },
                }}
                onClick={() => navigate(section?.btnLink)}
              >
                {section?.btnText}
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Cards;
