import Footer from "../../components/footer/footer"
import NavBar from "../../components/navbar/NavBar"
import ScrollToTop from "../../hooks/ScrollToTop"
import { ProductDetails } from "./product-details/ProductDetails"

export const Products = () => {
  return (
    <>
    <ScrollToTop/>
    <NavBar/>
    <ProductDetails />
    <Footer/>
    </>
  )
}
