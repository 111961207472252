export const pricingCardData = [
  {
    title: "Free Plan",
    description: "Start with spark",
    btnText: "Get Started",
    btnLink: "/get-started",
    users: '1 User (Per Module)',
    detail: [
      {
        title: "E-Inspect",
        amount: '£0/month',
        items: [
          "Template Creation",
          "Scheduling",
          "Inspection",
          "Issues Recording",
          "Basic Analytics",
          "Email Support",
          "Up to 100 inspections per month",
        ],
      },
      {
        title: "Well Being",
        amount: '£0/month',
        items: [
          "Time Tracker",
          "Incident Reporting",
          "Basic Activity Tracking",
          "Basic Employee Shift Activity Management",
          "Easy Access Forms for Lone Workers",
          "Email Support",
          "Up to 100 incidents per month",
        ],
      },
      {
        title: "Assurance Pack",
        amount: '£0/month',
        items: [
          "HESQ Implementation",
          "Basic Document Management",
          "Worker Contract Management",
          "Basic Risk Management",
          "Basic Environment & Sustainability Tracking",
          "Email Support",
          "Up to 100 documents stored",
        ],
      },
      {
        title: "Learning & Development",
        amount: '£0/month',
        items: [
          "CRM",
          "Single Admin",
          "3 Course Creation",
          "3 Quiz Creation",
          "2 Practical Assessments",
          "1 Group Discussion",
          "2 Paths Creation",
          "3 Custom Certificates",
          "Limited Reports",
          "Learners Reports",
          "Email Support",
        ],
      },
    ],
  },
  {
    title: "Basic Plan",
    description: "For growing teams",
    btnText: "Contact Us",
    btnLink: "/contact-us",
    users: 'Up to 10 Users (Per Module)',
    detail: [
      {
        title: "E-Inspect",
        amount: '£56/month',
        items: [
          "All features of the Free Plan",
          "Unlimited inspections",
          "Actions Assignment",
          "HeadsUp Notifications",
          "Assets Management (up to 100 assets)",
          "Advanced Analytics",
          "Standard Email Support",
        ],
      },
      {
        title: "Well Being",
        amount: '£49/month',
        items: [
          "All features of the Free Plan",
          "Advanced Activity Tracking",
          "Perform Audits",
          "Priority Email Support",
          "Unlimited incidents",
        ],
      },
      {
        title: "Assurance Pack",
        amount: '£43/month',
        items: [
          "All features of the Free Plan",
          "Advanced Document Management",
          "Advanced Risk Management",
          "Priority Email Support",
          "Unlimited documents stored",
        ],
      },
      {
        title: "Learning & Development",
        amount: '£69/month',
        items: [
          "CRM",
          "Single Admin",
          "5 Course Creation",
          "5 Quiz Creation",
          "3 Practical Assessments",
          "2 Group Discussion",
          "4 Paths Creation",
          "5 Custom Certificates",
          "Branding of Courses",
          "Limited Reports",
          "Learners Reports",
          "Priority Email Support",
        ],
      },
    ],
  },
  {
    title: "Enterprise Plan",
    description: "For large organisations",
    btnText: "Contact Us",
    btnLink: "/contact-us",
    users: 'Unlimited Users',
    detail: [
      {
        title: "E-Inspect",
        amount: '£109/month',
        items: [
          "All features of the Basic Plan",
          "Sensors Integration",
          "Advanced Customisation",
          "Priority Support",
          "Unlimited Assets Management",
          "Dedicated Account Manager",
        ],
      },
      {
        title: "Well Being",
        amount: '£99/month',
        items: [
          "All features of the Basic Plan",
          "Share Live Location",
          "Advanced Employee Shift Activity Management",
          "Advanced Reporting for Incidents",
          "Dedicated Account Manager",
          "Custom Integrations",
          "Advanced Security Features",
          "Tailored Onboarding and Training",
        ],
      },
      {
        title: "Assurance Pack",
        amount: '£88/month',
        items: [
          "All features of the Basic Plan",
          "Custom Integrations",
          "Dedicated Account Manager",
          "Advanced Security Features",
          "Tailored Onboarding and Training",
          "Advanced Environment & Sustainability Tracking",
          "Compliance Dashboard",
          "Advanced Reporting and Analytics",
        ],
      },
      {
        title: "Learning & Development",
        amount: '£135/month',
        items: [
          "CRM",
          "Multiple Admins/Facilitators",
          "Unlimited Course Creation",
          "Unlimited Quiz Creation",
          "Unlimited Practical Assessments",
          "Unlimited Group Discussion",
          "Unlimited Paths Creation",
          "Unlimited Custom Certificates",
          "Branding of Courses",
          "Complete Reports",
          "Learners Reports",
          "Custom Integrations",
          "Dedicated Account Manager",
          "Advanced Security Features",
          "Tailored Onboarding and Training",
        ],
      },
    ],
  },
];
