export const termsConditionData = [
    {
        id: 1,
        heading: "Use of the Website",
        detail: `You agree to use our Website in accordance with these Terms and all applicable laws and regulations. You must not use our Website in any way that causes, or may cause, damage to the Website or impairment of the availability or accessibility of the Website.`,
      },
      {
        id: 2,
        heading: "Intellectual Property Rights",
        detail: `Unless otherwise stated, we or our licensors own the intellectual property rights in the Website and material on the Website. All these intellectual property rights are reserved.`,
      },
      {
        id: 3,
        heading: "User Content",
        detail: `You retain ownership of any intellectual property rights that you hold in content you upload or submit to the Website. By uploading or submitting content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute such content in connection with our services.`,
      },
      {
        id: 4,
        heading: "Privacy",
        detail: `Our use of your personal data is governed by our Privacy Policy, which complies with the UK General Data Protection Regulation (GDPR). By using our Website, you consent to such processing and warrant that all data provided by you is accurate.`,
      },
      {
        id: 5,
        heading: "Limitation of Liability",
        detail: `The content on our Website is provided for general information only. We do not warrant that the Website will be available, uninterrupted, or error-free, nor do we warrant the accuracy, completeness, or reliability of any content on the Website. To the extent permitted by law, we exclude all conditions, warranties, representations, or other terms which may apply to our Website or any content on it, whether express or implied.`,
        content:
          "We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:",
        points: [
          "Use of, or inability to use, our Website.",
          "Use of or reliance on any content displayed on our Website.",
        ],
      },
      {
        id: 6,
        heading: "Third-Party Links",
        detail: `Our Website may contain links to third-party websites. These links are provided for your convenience only. We have no control over the contents of those sites and accept no responsibility for them or for any loss or damage that may arise from your use of them.`,
      },
      {
        id: 7,
        heading: "Modifications",
        detail: `We may revise these Terms at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we made, as they are binding on you. Continued use of the Website signifies acceptance of the updated Terms.`,
      },
      {
        id: 8,
        heading: "Governing Law",
        detail: `These Terms are governed by and construed in accordance with the laws of England and Wales. Any disputes arising in connection with these Terms will be subject to the exclusive jurisdiction of the courts of England and Wales.`,
      },
      {
        id: 9,
        heading: "Contact Us",
        detail: `For any questions or concerns about these Terms, please contact us via the Contact Us page on our Website or at:`,
      },
]