export const CheckboxCheckedIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4781_23741)">
        <circle cx="12.5" cy="12" r="12" fill="#697AE2" />
        <path
          d="M6.80889 12.1783C6.39704 11.7826 6.39704 11.1411 6.80889 10.7454C7.22075 10.3497 7.8885 10.3497 8.30036 10.7454L11.9694 14.2703C12.3812 14.666 12.3812 15.3075 11.9694 15.7032C11.5575 16.0989 10.8898 16.0989 10.4779 15.7032L6.80889 12.1783Z"
          fill="white"
        />
        <path
          d="M16.6996 8.29676C17.1115 7.90108 17.7792 7.90108 18.1911 8.29676C18.603 8.69245 18.603 9.33399 18.1911 9.72967L12.042 15.6373C11.6302 16.033 10.9624 16.033 10.5506 15.6373C10.1387 15.2416 10.1387 14.6001 10.5506 14.2044L16.6996 8.29676Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4781_23741">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
