import React from 'react'
import PricingFirstS from './PricingFirstS'
import NavBar from '../../../components/navbar/NavBar'
import Footer from '../../../components/footer/footer'
import ScrollToTop from '../../../hooks/ScrollToTop'

const PricingBody = () => {
  return (
    <>
    <ScrollToTop/>
    <NavBar/>
    <PricingFirstS/>
    <Footer/>
    </>

  )
}

export default PricingBody