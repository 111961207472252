import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomTextField from "../../components/custom-textfield/CustomTextField";
import { GetStartedModal } from "./GetStartedModal";
import { ClipLoader } from "react-spinners";

export const GetStartedForm = ({ formik, setOpen, open, loading }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Container>
      <Card
        sx={{
          background: "white",
          borderRadius: "8px",
          p: "40px 30px",
          mb: "20px",
          width: { md: "100%", xs: 'fit-content' },
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "600",
              fontFamily: "Red Hat Display",
              color: "#697AE2",
            }}
          >
            Personal Information
          </Typography>
        </Box>
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="First Name"
              id="firstName"
              placeholder="Enter your First Name"
              value={formik.values.firstName}
              require
              onChange={(event) => {
                const alphanumericRegex = /^[a-z0-9]+$/i;
                if (alphanumericRegex.test(event.target.value) || event.target.value === '') {
                  formik.handleChange(event);
                }
              }}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="Last Name"
              id="lastName"
              placeholder="Enter your Last Name"
              value={formik.values.lastName}
              require
              onChange={(event) => {
                const alphanumericRegex = /^[a-z0-9]+$/i;
                if (alphanumericRegex.test(event.target.value) || event.target.value === '') {
                  formik.handleChange(event);
                }
              }}
              error={
                formik.touched.lastName && Boolean(formik.errors.lastName)
              }
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="Business Name"
              id="businessName"
              placeholder="Enter your Business Name"
              value={formik.values.businessName}
              onChange={formik.handleChange}
              require
              error={
                formik.touched.businessName &&
                Boolean(formik.errors.businessName)
              }
              helperText={
                formik.touched.businessName && formik.errors.businessName
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="Business Email"
              id="email"
              placeholder="Enter your Business Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              require
              error={
                formik.touched.email &&
                Boolean(formik.errors.email)
              }
              helperText={
                formik.touched.email && formik.errors.email
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="CRN Number"
              id="crn"
              placeholder="Enter your Business Domain"
              value={formik.values.crn}
              onChange={formik.handleChange}
              require
              error={
                formik.touched.crn &&
                Boolean(formik.errors.crn)
              }
              helperText={
                formik.touched.crn && formik.errors.crn
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CustomTextField
              label="Contact Number"
              id="contactNumber"
              placeholder="Enter your Phone Number"
              value={formik.values.contactNumber}
              onChange={formik.handleChange}
              require
              error={
                formik.touched.contactNumber &&
                Boolean(formik.errors.contactNumber)
              }
              helperText={
                formik.touched.contactNumber && formik.errors.contactNumber
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Address"
              id="address"
              placeholder="Enter your address"
              value={formik.values.address}
              onChange={formik.handleChange}
              require
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Password"
              id="password"
              fieldType={showPassword ? 'text' : 'password'}
              placeholder="Enter Password"
              value={formik.values.password}
              onChange={formik.handleChange}
              require
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              endIcon={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          disabled={loading}
          sx={{
            my: "20px",
            py: "12px",
            background: "#697AE2",
            fontWeight: "500",
            textTransform: "none",
            color: "#fff",
            "&:hover": {
              background: "#5567d4",
            },
          }}
        >
          {loading ? <ClipLoader size={12} /> : 'Get Started'}
        </Button>
      </Card>
      <GetStartedModal open={open} setOpen={setOpen} />
    </Container>
  );
};
