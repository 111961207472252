import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import MainBG from "../../assests/background.png";
import { Alert, Box, Container, Grid, Snackbar } from "@mui/material";
import Logo from "../../assests/logo.svg";
import { GetStartedForm } from "./GetStartedForm";
import { initialValues, validationSchema } from "./GetStarted.data";
import { AUTH_BASE_URL, ENQUIRIES_API_KEY } from "../../config";

export const GetStarted = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    val: false,
    variant: "",
  });
  const methods = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const response = await axios?.post("/auth/signup", values, {
          baseURL: AUTH_BASE_URL,
        });
        setOpen({ val: true, res: response.data.data?.userId });
        setSnackbarMessage({
          message: "Sign up successful! User ID",
          val: true,
          variant: "success",
        });
        resetForm();
      } catch (error) {
        setSnackbarMessage({
          message: error.response.data.message,
          val: true,
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
  });
  return (
    <>
      <form onSubmit={methods?.handleSubmit}>
        <Box
          sx={{
            width: "100%",
            height: { md: "100vh", xs: "150vh" },
            backgroundImage: `url(${MainBG})`,
          }}
        >
          <Container>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              gap={{ md: 10, xs: 7 }}
              height={"100vh"}
              sx={{ pt: "20px" }}
            >
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { md: "flex-end", xs: "center" },
                  mb: { md: "80px" },
                  width: { md: "443px" },
                  height: { md: "137px" },
                }}
              >
                <img src={Logo} alt="Logo" />
              </Grid>
              <Grid item md={6.5} xs={12}>
                <GetStartedForm
                  formik={methods}
                  setOpen={setOpen}
                  open={open}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </form>
      <Snackbar
        open={snackbarMessage?.val}
        autoHideDuration={5000}
        onClose={() => setSnackbarMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={snackbarMessage?.variant}
          onClose={() => setSnackbarMessage(false)}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};
