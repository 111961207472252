import React from "react";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/footer";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import ScrollToTop from "../../hooks/ScrollToTop";
import { termsConditionData } from "./TermsAndCondition.data";

const TermsAndConditions = () => {
  return (
    <>
      <ScrollToTop />
      <NavBar />
      <Container>
        <Grid container justifyContent={"center"}>
          <Card
            sx={{
              width: "100%",
              background: "#F0F2FC",
              p: { md: "50px", xs: "10px" },
              borderRadius: "15px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              mb: "40px",
            }}
          >
            <Typography
              className={`item-center font-size-on-small`}
              sx={{
                fontSize: "48px",
                fontWeight: "700",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
                textAlign: "center",
                pb: "20px",
              }}
            >
              Terms & Conditions
            </Typography>
            <Typography
              textAlign="center"
              fontFamily="Red Hat Display"
              fontSize={15}
            >
              These terms and conditions ("Terms") govern your use of the
              Compliance Sheet website (the "Website") operated by{" "}
              <b>Pay io Services LTD </b>("we," "our," or "us"). We are
              registered in England and Wales under company number 15439659 with
              its registered office at International House, 36-38 Cornhill,
              London, England, EC3V 3NG, United Kingdom. By accessing or using
              our Website, you agree to comply with these Terms. Please read
              them carefully.
            </Typography>
            <Box height={"100%"} width={"100%"} padding={"20px 0"}>
              {termsConditionData?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Red Hat Display",
                        color: "#565666",
                        py: "10px",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        fontFamily: "Red Hat Display",
                        color: "#565666",
                        mb: "10px",
                      }}
                    >
                      {item.detail}
                    </Typography>
                    {item?.content && (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          fontFamily: "Red Hat Display",
                          color: "#565666",
                          mb: "10px",
                        }}
                      >
                        {item?.content}
                      </Typography>
                    )}
                    {item?.points &&
                      item?.points?.map((li) => (
                        <Typography
                          component={"li"}
                          color="#565666"
                          fontFamily="Red Hat Display"
                        >
                          {li}
                        </Typography>
                      ))}
                    {item?.id === 9 && (
                      <Typography color="#565666" fontFamily="Red Hat Display">
                        Compliance Sheet <br /> International House, 36-38
                        Cornhill, London, England, EC3V 3NG, United Kingdom{" "}
                        <br />
                        <b>Email:</b> Info@compliancesheet.co.uk
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Card>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
