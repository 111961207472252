import { Alert, Box, Dialog, Snackbar, Typography } from "@mui/material";
import { SendEmailImage } from "../../assests/SendEmailImage";
import { useState, useEffect } from "react";
import { AUTH_BASE_URL } from "../../config";
import axios from "axios";
import { BeatLoader } from "react-spinners";

export const GetStartedModal = ({ open, setOpen }) => {
  const [timer, setTimer] = useState(60);
  const [showTimer, setShowTimer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    val: false,
    res: "",
    variant: "",
  });

  useEffect(() => {
    if (open && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setShowTimer(false);
    } else {
      setTimer(60);
      setShowTimer(true);
    }
  }, [open, timer]);
  const handleResendEmail = async () => {
    try {
      setLoading(true);
      const response = await axios?.post(
        "/auth/resend-verificaiton-link",
        { userId: open?.res },
        {
          baseURL: AUTH_BASE_URL,
        }
      );
      setSnackbarMessage({
        message: response?.data?.message,
        val: true,
        variant: "success",
      });
      !showTimer && setShowTimer(true);
      setTimer(60);
    } catch (error) {
      setSnackbarMessage({
        message: error.response.data.message,
        val: true,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Dialog open={open?.val} onClose={() => setOpen(false)} fullWidth>
        <Box p={6} display="flex" flexDirection="column" alignItems="center">
          <SendEmailImage />
          <Typography
            mt={5}
            fontFamily="Red Hat Text"
            fontSize={16}
            color="#565666"
          >
            Thank you for showing your interest in compliance sheet, We have
            sent an email on the provided email address for the Identity
            Verification by Identity Gram. Please check your email and verify
            your ID
          </Typography>
          <Typography
            mt={5}
            fontFamily="Red Hat Text"
            fontSize={16}
            color="#697AE2"
            sx={{ cursor: !showTimer && "pointer", width: "fit-content" }}
            onClick={!showTimer ? handleResendEmail : () => {}}
          >
            {loading ? (
              <BeatLoader />
            ) : (
              `Resend Email${showTimer ? `: ${timer}` : ""}`
            )}
          </Typography>
        </Box>
      </Dialog>
      <Snackbar
        open={snackbarMessage?.val}
        autoHideDuration={5000}
        onClose={() => setSnackbarMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={snackbarMessage?.variant}
          onClose={() => setSnackbarMessage(false)}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};
