export const privacyPolicyData = [
    {
      id: 1,
      heading: "Information We Collect",
      detail: "We may collect and process the following data about you:",
      points: [
        {
          title: "Personal Identification Information:",
          detail: "Name, email address, phone number, and other contact details.",
        },
        {
          title: "Technical Data:",
          detail:
            "IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system, and platform.",
        },
        {
          title: "Usage Data:",
          detail:
            "Information about how you use our Website, products, and services.",
        },
        {
          title: "Marketing and Communications Data:",
          detail:
            "Your preferences in receiving marketing from us and your communication preferences.",
        },
      ],
    },
    {
      id: 2,
      heading: "How We Collect Your Information",
      detail:
        "We use different methods to collect data from and about you, including:",
      points: [
        {
          title: "Direct Interactions:",
          detail:
            "You may give us your personal information by filling in forms or by corresponding with us by post, phone, email, or otherwise.",
        },
        {
          title: "Automated Technologies:",
          detail:
            "As you interact with our Website, we may automatically collect technical data about your equipment, browsing actions, and patterns.",
        },
      ],
    },
    {
      id: 3,
      heading: "How We Use Your Information",
      detail:
        "We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:",
      points: [
        {
          title: "To provide and manage our services:",
          detail: "Including managing payments, fees, and charges.",
        },
        {
          title: "To manage our relationship with you:",
          detail:
            "Including notifying you about changes to our terms or privacy policy.",
        },
        {
          title: "To administer and protect our business and this Website:",
          detail:
            "Including troubleshooting, data analysis, testing, system maintenance, support, reporting, and hosting of data.",
        },
        {
          title: "To deliver relevant website content and advertisements to you:",
          detail:
            "And measure or understand the effectiveness of the advertising we serve to you.",
        },
        {
          title: "To use data analytics:",
          detail:
            "To improve our Website, products/services, marketing, customer relationships, and experiences.",
        },
      ],
    },
    {
      id: 4,
      heading: "Disclosure of Your Information",
      detail: "We may share your personal information with:",
      points: [
        {
          title: "Service Providers:",
          detail: "Who provide IT and system administration services.",
        },
        {
          title: "Professional advisers:",
          detail: "Including lawyers, bankers, auditors, and insurers.",
        },
        {
          title: "HM Revenue & Customs, regulators, and other authorities:",
          detail:
            "Who require reporting of processing activities in certain circumstances.",
        },
      ],
    },
    {
      id: 5,
      heading: "Data Security",
      detail:
        "We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used, or accessed in an unauthorised way, altered, or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors, and other third parties who have a business need to know.",
    },
    {
      id: 6,
      heading: "Data Retention",
      detail:
        "We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.",
    },
    {
      id: 7,
      heading: "Your Legal Rights",
      detail:
        "Under certain circumstances, you have rights under data protection laws in relation to your personal data, including the right to:",
      points: [
        {
          title: "Request access:",
          detail: "To your personal data.",
        },
        {
          title: "Request correction:",
          detail: "Of the personal data that we hold about you.",
        },
        {
          title: "Request erasure:",
          detail: "Of your personal data.",
        },
        {
          title: "Object to processing:",
          detail: "Of your personal data.",
        },
        {
          title: "Request restriction of processing:",
          detail: "Of your personal data.",
        },
        {
          title: "Request transfer:",
          detail: "Of your personal data to you or to a third party.",
        },
        {
          title: "Withdraw consent:",
          detail:
            "At any time where we are relying on consent to process your personal data.",
        },
      ],
    },
    {
      id: 8,
      heading: "Changes to This Privacy Policy",
      detail:
        "We keep our privacy policy under regular review. Any changes we make to our privacy policy in the future will be posted on this page. Please check back frequently to see any updates or changes.",
    },
    {
      id: 9,
      heading: "Contact Us",
      detail:
        "If you have any questions about this privacy policy or our privacy practices, please contact us at Info@compliancesheet.co.uk",
    },
  ];