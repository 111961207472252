export const CheckboxIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4781_8109)">
        <circle cx="12.5" cy="12" r="12" fill="#D2D7F6" />
        <path
          d="M6.30889 12.7006C5.89704 12.2554 5.89704 11.5337 6.30889 11.0885C6.72075 10.6434 7.3885 10.6434 7.80036 11.0885L11.4694 15.0541C11.8812 15.4993 11.8812 16.221 11.4694 16.6661C11.0575 17.1113 10.3898 17.1113 9.97791 16.6661L6.30889 12.7006Z"
          fill="white"
        />
        <path
          d="M16.1996 8.33386C16.6115 7.88871 17.2792 7.88871 17.6911 8.33386C18.103 8.77901 18.103 9.50073 17.6911 9.94588L11.542 16.592C11.1302 17.0371 10.4624 17.0371 10.0506 16.592C9.6387 16.1468 9.6387 15.4251 10.0506 14.98L16.1996 8.33386Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4781_8109">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
