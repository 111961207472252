import React from "react";
import DGPIC3 from "../../assests/bg3.png";
import { Grid, IconButton, Typography } from "@mui/material";
import Logo from "../../assests/logo.svg";
import Facebook from "../../assests/fb.svg";
import Tweeter from "../../assests/x.svg";
import YouTube from "../../assests/yt.svg";
import LinkedIn from "../../assests/link.svg";
import Pinterest from "../../assests/p.svg";
import Insta from "../../assests/insta.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer2 from "./footer-for-S/Footer2";
import { Link } from "react-router-dom";

const Footer = () => {
  const matches = useMediaQuery("(min-width:600px) and (min-width:1200px)");
  return (
    <>
      {matches && (
        <div
          style={{
            height: "auto",
            flex: 1,
            background: `url(${DGPIC3}) no-repeat right`,
            backgroundPosition: "right",
          }}
        >
          <Grid container p={5}>
            <Grid item xl={3} lg={3} sx={{ pl: "30px", mt: "8px" }}>
              <Typography>
                <img src={Logo} alt="Logo" />
              </Typography>
              <Typography
                sx={{
                  width: "85%",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "Red Hat Display",
                  mt: "10px",
                  color: " #565666",
                }}
              >
                Compliance Sheet empowers businesses of all sizes to conduct
                inspections, identity issues, manage tasks, ensures clear
                communication, and provide training to their working teams, thus
                enabling them to efficiently accomplish their job tasks.
              </Typography>
            </Grid>
            <Grid item lg={3} sx={{ pl: "40px", mt: "40px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#565666",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                }}
              >
                Contact info
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  mt: "20px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Red Hat Display",
                    fontWeight: "600",
                    color: "#565666",
                  }}
                >
                  Address:
                </span>{" "}
                House, 36-38 Cornhill, London, England,
                <br /> EC3V 3NG
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  mt: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Red Hat Display",
                    fontWeight: "600",
                    color: "#565666",
                    marginRight: "5px",
                  }}
                >
                  Phone:
                </span>
                +44 330 043 1210
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  mt: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Red Hat Display",
                    fontWeight: "600",
                    color: "#565666",
                    marginRight: "5px",
                  }}
                >
                  Email:
                </span>
                info@compliancesheet.co.uk
              </Typography>
            </Grid>
            <Grid item xl={3} lg={3} sx={{ pl: "40px", mt: "40px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#565666",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                }}
              >
                Important Links
              </Typography>
              <Link Link to="/products" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "20px",
                    cursor: "pointer",
                  }}
                >
                  Products
                </Typography>
              </Link>
              <Link Link to="/vision" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Vision
                </Typography>
              </Link>
              <Link Link to="/solution" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Solutions
                </Typography>
              </Link>
              <Link Link to="/pricing" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Pricing
                </Typography>
              </Link>
              <Link Link to="/career" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Career
                </Typography>
              </Link>
              <Link Link to="/contact-us" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Contact Us
                </Typography>
              </Link>
            </Grid>
            <Grid item xl={3} lg={3} sx={{ pl: "40px", mt: "40px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  color: "#565666",
                  fontWeight: "600",
                  fontFamily: "Red Hat Display",
                }}
              >
                Support
              </Typography>
              <Link
                Link
                to="/terms-and-conditions"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "20px",
                    cursor: "pointer",
                  }}
                >
                  Terms & Conditions
                </Typography>
              </Link>
              <Link
                Link
                to="/privacy-policy"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </Typography>
              </Link>
              <Link
                Link
                to="/cookies-policy"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Red Hat Display",
                    color: "#565666",
                    mt: "10px",
                    cursor: "pointer",
                  }}
                >
                  Cookies Policy
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <div style={{ borderBottom: "1px solid #A6A6B3" }}></div>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ px: "40px" }}
          >
            <Grid item md={4}>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  fontSize: "14",
                }}
              >
                @ Compliance Sheet 2024, All rights reserved
              </Typography>
            </Grid>
            <Grid item md={4.5} sx={{ display: "flex" }}>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  fontSize: "14",
                }}
              >
                <Link
                  Link
                  to="/terms-and-conditions"
                  style={{ textDecoration: "none", color: "#565666" }}
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  fontSize: "14",
                  px: "20px",
                }}
              >
                <Link
                  Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none", color: "#565666" }}
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#565666",
                  fontSize: "14",
                }}
              >
                <Link
                  Link
                  to="/cookies-policy"
                  style={{ textDecoration: "none", color: "#565666" }}
                >
                  Cookies Policy
                </Link>
              </Typography>
            </Grid>
            <Grid
              item
              md={2.3}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton>
                <Link
                  to="https://www.facebook.com/compliancesheet/"
                  target="_blank"
                >
                  <img src={Facebook} alt="Facebook" />
                </Link>
              </IconButton>
              <IconButton sx={{mt: 1.6}}>
                <Link to="https://x.com/complianceshet" target="_blank">
                  <img src={Tweeter} alt="Tweeter" />
                </Link>
              </IconButton>
              <IconButton>
                <Link
                  to="https://www.youtube.com/@compliancesheet_/featured"
                  target="_blank"
                >
                  <img src={YouTube} alt="YouTube" />
                </Link>
              </IconButton>
              <IconButton sx={{ mb: -1.2  }}>
                <Link
                  to="https://www.linkedin.com/company/compliancesheet/"
                  target="_blank"
                >
                  <img src={LinkedIn} alt="LinkedIn" />
                </Link>
              </IconButton>
              <IconButton sx={{ mb: 0.2,  }}>
                <Link
                  to="https://www.pinterest.com/compliancesheet/"
                  target="_blank"
                >
                  <img src={Pinterest} alt="Pinterest" />
                </Link>
              </IconButton>
              <IconButton sx={{ mb: 1,  }}>
                <Link
                  to="https://www.instagram.com/compliancesheet_/"
                  target="_blank"
                >
                  <img src={Insta} alt="Insta" />
                </Link>
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}
      {!matches && (
        <div>
          <Footer2 />
        </div>
      )}
    </>
  );
};

export default Footer;
