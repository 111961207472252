import { useEffect, useState } from "react";
import {
  Toolbar,
  Button,
  Tabs,
  Tab,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
} from "@mui/material";
import Logo from "../../assests/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExitToApp } from "@mui/icons-material";

const NavBar = () => {
  const location = useLocation();
  const [resourcesMenuAnchor, setResourcesMenuAnchor] = useState(null);
  const [supportMenuAnchor, setSupportMenuAnchor] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [session, setSession] = useState(null);

  const pathname = window?.location?.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    const storedSession = localStorage.getItem("session");
    if (storedSession) {
      setSession(storedSession);
    }
  }, []);
  const closeResourcesMenu = () => {
    setResourcesMenuAnchor(null);
  };
  const closeSupportMenu = () => {
    setSupportMenuAnchor(null);
  };
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    localStorage?.clear();
    setSession(null);
  };
  const handleLogoutRouter = () => {
    localStorage?.removeItem('session')
    navigate('/sign-in');

  }
  useEffect(() => {
    if (!localStorage.getItem("session")) {
      return;
    }
    const timeoutId = setTimeout(() => {
      handleLogoutRouter();
      // enqueueSnackbar("Session Expired", { variant: "error" });

      setTimeout(() => {
        navigate("/sign-in");
      }, 2000);
    }, 30 * 60 * 1000);
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div
      style={{
        padding: "10px",
        position: "sticky",
        top: "0",
        zIndex: "100",
        backgroundColor: "#fff",
      }}
    >
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link to="/">
              <img src={Logo} alt="Logo" style={{ cursor: "pointer" }} />
            </Link>
          </Grid>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                  "& .MuiTabs-indicator": { background: "none" },
                },
              }}
            >
              <Link Link to="/" style={{ textDecoration: "none" }}>
                <Tab
                  label="Home"
                  value="1"
                  sx={{
                    color: pathname === "/" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/products" style={{ textDecoration: "none" }}>
                <Tab
                  label="Products"
                  value="2"
                  sx={{
                    color: pathname === "/products" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/products" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/vision" style={{ textDecoration: "none" }}>
                <Tab
                  label="Vision"
                  value="3"
                  sx={{
                    color: pathname === "/vision" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/vision" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/solution" style={{ textDecoration: "none" }}>
                <Tab
                  label="Solutions"
                  value="4"
                  sx={{
                    color: pathname === "/solution" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/solution" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/pricing" style={{ textDecoration: "none" }}>
                <Tab
                  label="Pricing"
                  value="5"
                  sx={{
                    color: pathname === "/pricing" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/pricing" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/career" style={{ textDecoration: "none" }}>
                <Tab
                  label="Career"
                  value="6"
                  sx={{
                    color: pathname === "/career" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/career" ? 700 : 400,
                  }}
                />
              </Link>
              <Link Link to="/contact-us" style={{ textDecoration: "none" }}>
                <Tab
                  label="Contact Us"
                  value="7"
                  sx={{
                    color: pathname === "/contact-us" ? "#5567D4" : "#565666",
                    fontWeight: pathname === "/contact-us" ? 700 : 400,
                  }}
                />
              </Link>
            </Tabs>
            <IconButton
              sx={{ display: { xs: "block", md: "none" } }}
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          {!session ? (
            <Grid item className={`hide-on-xs hide-on-sm-md`}>
              <Link Link to="/sign-in" style={{ textDecoration: "none" }}>
                {location.pathname !== '/sign-in' && <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    fontWeight: "500",
                    color: "#697AE2",
                    textTransform: "none",
                    mr: "10px",
                  }}
                >
                  Sign In
                </Button>}
              </Link>
              <Link Link to="/get-started" style={{ textDecoration: "none" }}>
                {location.pathname !== '/get-started' && <Button
                  size="large"
                  variant="contained"
                  sx={{
                    background: "#697AE2",
                    fontWeight: "500",
                    textTransform: "none",
                    color: "#fff",
                    "&:hover": {
                      background: "#5567d4",
                    },
                  }}
                >
                  Get Started
                </Button>}
              </Link>
            </Grid>
          ) : (
            <Grid item className={`hide-on-xs hide-on-sm-md`}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  fontWeight: "500",
                  color: "#697AE2",
                  textTransform: "none",
                  mr: "10px",
                }}
                startIcon={<ExitToApp sx={{ transform: "scaleX(-1)" }} />}
                onClick={handleLogoutRouter}
              >
                Logout
              </Button>
            </Grid>
          )}
        </Grid>
      </Toolbar>
      <Menu
        anchorEl={resourcesMenuAnchor}
        open={Boolean(resourcesMenuAnchor)}
        onClose={closeResourcesMenu}
      >
        <MenuItem onClick={closeResourcesMenu}>Option 1</MenuItem>
        <MenuItem onClick={closeResourcesMenu}>Option 2</MenuItem>
      </Menu>
      <Menu
        anchorEl={supportMenuAnchor}
        open={Boolean(supportMenuAnchor)}
        onClose={closeSupportMenu}
      >
        <MenuItem onClick={closeSupportMenu}>Option 1</MenuItem>
        <MenuItem onClick={closeSupportMenu}>Option 2</MenuItem>
      </Menu>
      <Drawer anchor="right" open={mobileMenuOpen} onClose={closeMobileMenu}>
        <div
          style={{ width: "250px", padding: "20px" }}
          role="presentation"
          onClick={closeMobileMenu}
        >
          <Tabs orientation="vertical" sx={{ textAlign: "center" }}>
            <Link Link to="/" style={{ textDecoration: "none" }}>
              <Tab label="Home" value="1" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/products" style={{ textDecoration: "none" }}>
              <Tab label="Products" value="2" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/product" style={{ textDecoration: "none" }}>
              <Tab label="Product" value="3" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/solution" style={{ textDecoration: "none" }}>
              <Tab label="Solutions" value="4" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/pricing" style={{ textDecoration: "none" }}>
              <Tab label="Pricing" value="5" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/career" style={{ textDecoration: "none" }}>
              <Tab label="Career" value="6" sx={{ color: "#565666" }} />
            </Link>
            <Link Link to="/contact-us" style={{ textDecoration: "none" }}>
              <Tab label="Contact Us" value="7" sx={{ color: "#565666" }} />
            </Link>
            {!session && (
              <Link Link to="/sign-in" style={{ textDecoration: "none" }}>
                <Tab sx={{ color: "#565666" }} value="8" label="Sign In" />
              </Link>
            )}
            {!session && (
              <Link Link to="/get-started" style={{ textDecoration: "none" }}>
                <Tab sx={{ color: "#565666" }} value="9" label="Get Started" />
              </Link>
            )}
            {session && (
              <Link Link style={{ textDecoration: "none" }}>
                <Tab
                  onClick={handleLogout}
                  sx={{ color: "#565666" }}
                  value="9"
                  label="Logout"
                />
              </Link>
            )}
          </Tabs>
        </div>
      </Drawer>
    </div>
  );
};

export default NavBar;
