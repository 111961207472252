import { Box, Container, Typography } from "@mui/material";

const Body = () => {
  return (
    <>
      <Container
        sx={{
          maxWidth: "90%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          mb: 8,
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: "48px",
            fontWeight: "700",
            color: "#565666",
            fontFamily: "Red Hat Display",
            display: "flex",
            justifyContent: "center",
            pt: "20px",
          }}
        >
          Careers
        </Typography>
        <Typography
          className={`font-size-on-small-40px item-center`}
          sx={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#565666",
            fontFamily: "Red Hat Display",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Join Our Team and Drive Compliance Excellence
        </Typography>
        <Typography
          className={`font-size-on-small-40px item-center`}
          sx={{
            fontSize: "20px",
            color: "#565666",
            fontFamily: "Red Hat Display",
            display: "flex",
            justifyContent: "center",
            mb: 1
          }}
        >
          At Compliance Sheet, we are committed to creating safer and more
          efficient workplaces. Join us to be part of a dynamic team dedicated
          to innovation, growth, and making a difference. Explore our
          opportunities and take your career to the next level with us.
        </Typography>
      </Container>
      <Box>
        <iframe
          src="https://recruiting-stg.personnellibrary.co.uk//job-boards?boardName=firstBoards-1716270157876"
          width="100%"
          height="100%"
          no-border
          title="Jobs Widget"
          style={{ border: "none", minHeight: "950px", overflowY: "scroll" }}
        />
      </Box>
    </>
  );
};

export default Body;
