import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
} from "@mui/material";
import CustomTextField from "../../components/custom-textfield/CustomTextField";
import axios from "axios";
import { AAC_BASE_URL, ENQUIRIES_API_KEY } from "../../config";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .required("Phone Number is required")
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Phone number is not valid"),
  query: Yup.string().required("Message are required"),
});

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phoneNumber: "",
  query: "",
};

const ContactForm = () => {
  const [open, setOpen] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";
  const handleClose = () => {
    setTimeout(() => {
      setOpen("");
    }, 500);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const modifiedValues = {
        name: `${values.first_name} ${values.last_name}`,
        email: values.email,
        phoneNumber: values.phoneNumber,
        query: values.query,
      };
      try {
        await axios.post(
          "/enquiries",
          modifiedValues,
          {
            headers: {
              "x-api-key": ENQUIRIES_API_KEY,
            },
            baseURL: AAC_BASE_URL,
          }
        );
        setOpen("success");
        resetForm();
      } catch (error) {
        setOpen("error");
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <CustomTextField
              label="First Name"
              id="first_name"
              require
              placeholder="Enter your First Name"
              value={formik.values.first_name}
              onChange={(event) => {
                const alphanumericRegex = /^[a-z0-9]+$/i;
                if (alphanumericRegex.test(event.target.value)) {
                  formik.handleChange(event);
                }
              }}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <CustomTextField
              label="Last Name"
              id="last_name"
              require
              placeholder="Enter your Last Name"
              value={formik.values.last_name}
              onChange={(event) => {
                const alphanumericRegex = /^[a-z0-9]+$/i;
                if (alphanumericRegex.test(event.target.value)) {
                  formik.handleChange(event);
                }
              }}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
        </Grid>
        <Grid Container justifyContent={"center"}>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <CustomTextField
              label="Email"
              id="email"
              require
              placeholder="Enter your Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: "15px" }}>
            <CustomTextField
              label="Phone Number"
              id="phoneNumber"
              require
              placeholder="Enter your Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              label="Message"
              id="query"
              placeholder="Enter query...."
              multiline={true}
              require
              rows={3}
              value={formik.values.query}
              onChange={formik.handleChange}
              error={formik.touched.query && Boolean(formik.errors.query)}
              helperText={formik.touched.query && formik.errors.query}
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          disabled={isLoading}
          sx={{
            my: "20px",
            py: "12px",
            background: "#697AE2",
            fontWeight: "500",
            textTransform: "none",
            color: "#fff",
            "&:hover": {
              background: "#5567d4",
            },
          }}
        >
          {isLoading ? <CircularProgress size={25} /> : "Send Message"}
        </Button>
      </form>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}
      // autoHideDuration={2000}
      >
        <Alert
          onClose={handleClose}
          severity={open === "success" ? "success" : "error"}
        >
          {open === "success"
            ? "Your message have been sent successfully! We will be in contact with you soon."
            : "Something went wrong! Please try again."}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ContactForm;
