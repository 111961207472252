import React from 'react'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/footer'
import Status from './status'

const StatusPage = () => {
  return (
    <>
     <NavBar/>
     <Status/>
     <Footer/> 
    </>
  )
}

export default StatusPage
