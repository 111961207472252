import React from "react";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/footer";
import SignIn from "./SignIn";

const SignInForm = () => {
  return (
    <>
      <NavBar />
      <SignIn />
      <Footer />
    </>
  );
};

export default SignInForm;
