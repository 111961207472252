import React, { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Homepage from "./layout/homepage/Homepage";
import SolutionBody from "./layout/solutions/components/body";
import PricingBody from "./layout/pricing/components/PricingBody";
import ProductBody from "./layout/product/ProductBody";
import Careers from "./layout/careerpage/Careers";
import Body from "./layout/contact-us/Body";
import BookDemo from "./layout/book-a-demo/BookDemo";
import EmailForm from "./layout/forgot-password/email-screen/EmailForm";
import NewPassword from "./layout/forgot-password/new-password/NewPassword";
import TermsAndConditions from "./layout/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./layout/privacy-policy/PrivacyPolicy";
import CookiesPolicy from "./layout/cookies-policy/CookiesPolicy";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { Products } from "./layout/products/Products";
import GetStartedForm from "./layout/get-started";
import SignInForm from "./layout/sign-in";
import StatusPage from "./layout/status";

const authPages = ["/sign-in", "/get-started"];
function App() {
  const session = localStorage.getItem("session");
  const [isSuccess, setIsSuccess] = useState(true);
  const navigate = useNavigate();
  const pathname = useLocation();

  useEffect(() => {
    const checkToken = async () => {
      if (session && authPages.includes(window.location.pathname)) {
        navigate("/status");
      } else {
         setIsSuccess(false);
      }
    };

    checkToken();
  }, [pathname]);
   if (isSuccess) {
     return (
       <Box
         sx={{
           textAlign: "center",
           margin: "auto",
           backgroundColor: "common.white",
           minHeight: "100vh",
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
         }}
       >
         <ClipLoader color="#698ee9" size={150} />
       </Box>
     );
   }
  return (
    <React.Suspense fallback={<h1>Loading</h1>}>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/vision" element={<ProductBody />} />
        <Route path="/solution" element={<SolutionBody />} />
        <Route path="/pricing" element={<PricingBody />} />
        <Route path="/career" element={<Careers />} />
        <Route path="/contact-us" element={<Body />} />
        <Route path="/sign-in" element={<SignInForm />} />
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
        <Route path="/book-a-demo" element={<BookDemo />} />
        <Route path="/forgot-password" element={<EmailForm />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/get-started" element={<GetStartedForm />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/status" element={<StatusPage />} />
        <Route path="/products" element={<Products />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
