import { Box, Typography } from "@mui/material";
import Cards from "../pricing-cards/Cards";



const PricingFirstS = () => {

  return (
      <Box
        sx={{
          background: "#F0F2FC",
          borderRadius: "24px",
          mx: {md:"25px",xs:'5px'},
          boxShadow: 'rgba(149, 157, 165, 0.3) 0px 8px 24px',
          px:'10px'
        }}
      >
        <Typography className={`font-size-on-small item-center`}
          sx={{
            fontSize: "48px",
            fontWeight: "700",
            color: "#565666",
            fontFamily: "Red Hat Display",
            display: "flex",
            justifyContent: "center",
            pt:'20px'
          }}
        >
          Our Plans
        </Typography>
        <Typography className={`font-size-on-small-40px item-center`}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#565666",
            fontFamily: "Red Hat Display",
            display: "flex",
            justifyContent: "center",
          }}
        >
          For teams of any size but with BIG ambitions.
        </Typography>
        <Typography>
          <Cards/>
        </Typography>
        <br />
        <br />
      </Box>
  );
};

export default PricingFirstS;
