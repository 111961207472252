import * as Yup from "yup";
export const initialValues = {
  firstName: "",
  lastName: "",
  businessName: "",
  email: "",
  crn: "",
  contactNumber: "",
  address: "",
  companyShortCode: "CS",
  password: "",
};

export const validationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  businessName: Yup.string().required("Business name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Business Email is required"),
  crn: Yup.string().required("CRN number is required"),
  contactNumber: Yup.string()
    .matches(/^\+44\d{10}$/, "Enter a valid UK phone number")
    .required("Contact number is required"),
  address: Yup.string().required("Address is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});
export const getStartedData = [
    "E-inspect",
    "L&D",
    "Assurance Pack",
    "Well-being",
  ];
