export const SendEmailImage = () => {
  return (
    <svg
      width="322"
      height="301"
      viewBox="0 0 322 301"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M321.547 300.559C321.547 300.675 249.565 300.767 160.774 300.767C71.982 300.767 0 300.682 0 300.559C0 300.436 71.974 300.344 160.774 300.344C249.573 300.344 321.547 300.452 321.547 300.559Z"
        fill="#263238"
      />
      <path
        d="M152.585 18.5968L144.714 24.3925L145.096 35.4604L137.893 29.9265L133.913 33.2207L118.791 15.1641L152.585 18.5968Z"
        fill="#697AE2"
      />
      <path
        d="M141.753 26.75C141.753 27.0887 145.096 35.4627 145.096 35.4627L137.893 29.9288L141.753 26.75Z"
        fill="#697AE2"
      />
      <g opacity="0.3">
        <path
          d="M141.753 26.75C141.753 27.0887 145.096 35.4627 145.096 35.4627L137.893 29.9288L141.753 26.75Z"
          fill="black"
        />
      </g>
      <path
        d="M118.791 15.1641L141.753 26.7477L145.096 35.4604L144.714 24.3925L118.791 15.1641Z"
        fill="#697AE2"
      />
      <path
        opacity="0.1"
        d="M118.791 15.1641L141.753 26.7477L145.096 35.4604L144.714 24.3925L118.791 15.1641Z"
        fill="black"
      />
      <path
        d="M182.462 164.497C181.047 164.452 179.657 164.124 178.379 163.535C179.794 163.58 181.184 163.908 182.462 164.497Z"
        fill="#263238"
      />
      <path
        d="M188.973 166.042C188.973 166.158 188.376 166.119 187.708 165.965C187.039 165.811 186.514 165.581 186.538 165.465C186.562 165.35 187.135 165.388 187.811 165.55C188.488 165.711 189.005 165.927 188.973 166.042Z"
        fill="#263238"
      />
      <path
        d="M162.104 34.0129C161.399 34.0229 160.696 33.9162 160.027 33.6974C159.326 33.6141 158.646 33.4081 158.021 33.0893C158.731 32.9992 159.453 33.0676 160.131 33.2894C160.839 33.3734 161.516 33.6216 162.104 34.0129Z"
        fill="#263238"
      />
      <path
        d="M169.808 37.211C168.473 36.7873 167.21 36.1752 166.06 35.3945C166.77 35.4599 167.455 35.6867 168.057 36.0564C169.1 36.5644 169.872 37.1109 169.808 37.211Z"
        fill="#263238"
      />
      <path
        d="M176.486 42.1007C176.398 42.1854 175.69 41.6081 174.798 40.8846C173.907 40.1611 173.206 39.5454 173.31 39.4453C173.989 39.6772 174.602 40.0594 175.101 40.5613C175.968 41.3002 176.573 42.0237 176.486 42.1007Z"
        fill="#263238"
      />
      <path
        d="M181.58 48.5283C181.468 48.5821 180.943 47.8356 180.306 46.9119C179.86 46.3826 179.51 45.7841 179.271 45.1417C179.359 45.0724 180.028 45.7343 180.672 46.681C181.122 47.2188 181.432 47.8518 181.58 48.5283Z"
        fill="#263238"
      />
      <path
        d="M184.469 56.1209C184.128 55.524 183.891 54.8763 183.769 54.2044C183.503 53.57 183.355 52.8952 183.331 52.2109C183.761 52.765 184.053 53.4078 184.183 54.0889C184.45 54.733 184.549 55.4311 184.469 56.1209Z"
        fill="#263238"
      />
      <path
        d="M184.898 64.2085C184.756 63.5402 184.726 62.8541 184.81 62.1766C184.75 61.4962 184.815 60.811 185.001 60.1523C185.244 60.8022 185.329 61.4976 185.248 62.1843C185.298 62.8763 185.178 63.57 184.898 64.2085Z"
        fill="#263238"
      />
      <path
        d="M182.727 72.0168C182.812 71.3411 183.011 70.6834 183.316 70.0695C183.478 69.4096 183.746 68.7784 184.111 68.1992C184.15 68.8927 184.005 69.5842 183.69 70.2081C183.528 70.8785 183.197 71.4998 182.727 72.0168Z"
        fill="#263238"
      />
      <path
        d="M177.862 78.582C177.783 78.4897 178.404 77.8124 179.16 76.9811C179.916 76.1499 180.521 75.4418 180.624 75.5264C180.398 76.1856 180.007 76.7805 179.486 77.2582C178.714 78.1202 177.942 78.6667 177.862 78.582Z"
        fill="#263238"
      />
      <path
        d="M170.842 82.9561C171.38 82.5163 171.987 82.1629 172.641 81.9094C173.228 81.5324 173.877 81.2516 174.559 81.0781C174.096 81.6107 173.506 82.0256 172.84 82.2865C172.241 82.6657 171.555 82.8957 170.842 82.9561Z"
        fill="#263238"
      />
      <path
        d="M162.676 84.655C163.342 84.4183 164.044 84.2908 164.753 84.2779C165.442 84.125 166.151 84.0808 166.854 84.147C166.23 84.4896 165.527 84.6773 164.809 84.6935C164.106 84.8563 163.372 84.843 162.676 84.655Z"
        fill="#263238"
      />
      <path
        d="M154.479 83.1562C155.839 83.4303 157.172 83.8245 158.458 84.3339C157.745 84.4199 157.02 84.3244 156.357 84.0568C155.66 83.9212 155.012 83.6106 154.479 83.1562Z"
        fill="#263238"
      />
      <path
        d="M148.668 77.5873C148.787 77.5488 149.153 78.3569 149.806 79.2651C150.459 80.1734 151.088 80.8045 151 80.92C150.912 81.0354 150.132 80.4582 149.448 79.5114C148.966 78.9761 148.691 78.2964 148.668 77.5873Z"
        fill="#263238"
      />
      <path
        d="M152.202 71.2802C152.202 71.4034 151.335 71.6727 150.427 72.2962C149.52 72.9196 149.035 73.697 148.923 73.6354C148.812 73.5738 149.162 72.6502 150.173 71.9575C150.735 71.4901 151.461 71.2479 152.202 71.2802Z"
        fill="#263238"
      />
      <path
        d="M159.397 74.1632C159.286 74.2248 158.776 73.4705 157.949 72.7316C157.121 71.9927 156.277 71.6233 156.357 71.5078C157.096 71.5642 157.785 71.8918 158.283 72.4237C158.855 72.8586 159.249 73.4746 159.397 74.1632Z"
        fill="#263238"
      />
      <path
        d="M159.668 82.0263C159.727 81.3557 159.858 80.6928 160.058 80.0483C160.117 79.3752 160.248 78.7098 160.448 78.0625C160.662 78.7346 160.662 79.4532 160.448 80.1252C160.393 80.8207 160.121 81.4838 159.668 82.0263Z"
        fill="#263238"
      />
      <path
        d="M154.216 88.0722C154.144 87.9721 154.868 87.3949 155.736 86.6791C156.604 85.9633 157.328 85.3475 157.384 85.4245C157.439 85.5015 156.922 86.2557 156.023 86.9638C155.123 87.6719 154.28 88.1722 154.216 88.0722Z"
        fill="#263238"
      />
      <path
        d="M146.774 91.8027C147.301 91.3419 147.918 90.9885 148.589 90.7636C149.201 90.4146 149.881 90.1918 150.587 90.1094C149.452 90.9242 148.153 91.501 146.774 91.8027Z"
        fill="#263238"
      />
      <path
        d="M139.285 95.4933C139.221 95.3933 139.977 94.8468 140.98 94.2619C141.565 93.8671 142.234 93.6041 142.938 93.4922C143.002 93.6076 142.19 94.0771 141.195 94.6313C140.627 95.0431 139.976 95.3367 139.285 95.4933Z"
        fill="#263238"
      />
      <path
        d="M132.917 100.789C132.83 100.712 133.379 99.958 134.215 99.1652C134.676 98.6373 135.256 98.2186 135.91 97.9414C135.99 98.0338 135.345 98.7111 134.525 99.4808C133.705 100.25 133.045 100.866 132.917 100.789Z"
        fill="#263238"
      />
      <path
        d="M128.143 107.422C128.276 106.742 128.57 106.102 129.002 105.551C129.615 104.589 130.244 103.896 130.339 103.966C130.133 104.62 129.807 105.232 129.376 105.774C128.771 106.721 128.254 107.475 128.143 107.422Z"
        fill="#263238"
      />
      <path
        d="M125.085 114.965C125.045 114.276 125.176 113.587 125.467 112.957C125.617 112.28 125.931 111.646 126.383 111.109C126.15 112.447 125.712 113.744 125.085 114.958V114.965Z"
        fill="#263238"
      />
      <path
        d="M123.988 122.998C123.699 121.648 123.798 120.248 124.274 118.949C124.396 119.621 124.396 120.309 124.274 120.981C124.302 121.664 124.205 122.347 123.988 122.998Z"
        fill="#263238"
      />
      <path
        d="M125.087 131.026C124.729 130.431 124.504 129.77 124.426 129.087C124.192 128.432 124.132 127.73 124.251 127.047C124.559 127.663 124.763 128.323 124.856 129.002C125.076 129.654 125.155 130.343 125.087 131.026Z"
        fill="#263238"
      />
      <path
        d="M128.325 138.488C128.222 138.55 127.657 137.811 127.139 136.795C126.756 136.211 126.523 135.545 126.463 134.855C126.921 135.376 127.282 135.97 127.529 136.61C127.907 137.186 128.176 137.822 128.325 138.488Z"
        fill="#263238"
      />
      <path
        d="M133.355 144.983C133.276 145.06 132.559 144.475 131.763 143.613C131.251 143.13 130.859 142.54 130.617 141.889C130.721 141.812 131.349 142.489 132.098 143.336C132.846 144.182 133.443 144.898 133.355 144.983Z"
        fill="#263238"
      />
      <path
        d="M139.818 150.136C139.126 149.956 138.488 149.621 137.955 149.158C137.016 148.496 136.363 147.835 136.427 147.742C137.084 147.998 137.69 148.363 138.218 148.82C139.141 149.474 139.881 150.036 139.818 150.136Z"
        fill="#263238"
      />
      <path
        d="M147.378 153.626C146.662 153.621 145.958 153.45 145.325 153.125C144.635 152.939 144.003 152.59 143.486 152.109C144.183 152.209 144.858 152.42 145.484 152.733C146.161 152.931 146.8 153.233 147.378 153.626Z"
        fill="#263238"
      />
      <path
        d="M151.589 154.665C151.589 154.773 151.517 154.865 151.477 154.858C151.438 154.85 151.422 154.75 151.477 154.634C151.533 154.519 151.549 154.434 151.589 154.442C151.629 154.45 151.613 154.55 151.589 154.665Z"
        fill="#263238"
      />
      <path
        d="M145.504 134.518L136.801 131.754L135.023 136.99L143.726 139.754L145.504 134.518Z"
        fill="#697AE2"
      />
      <g opacity="0.3">
        <path
          d="M145.505 134.506L136.802 131.742L135.024 136.978L143.727 139.742L145.505 134.506Z"
          fill="black"
        />
      </g>
      <path
        d="M145.484 134.525C143.857 135.314 142.17 135.983 140.438 136.526L140.287 136.58L140.192 136.457L140.04 136.272C138.833 134.852 137.744 133.341 136.785 131.754C138.097 133.085 139.302 134.51 140.391 136.018L140.534 136.203L140.287 136.133C141.972 135.463 143.71 134.925 145.484 134.525Z"
        fill="#697AE2"
      />
      <g opacity="0.1">
        <path
          d="M145.484 134.525C143.857 135.314 142.17 135.983 140.438 136.526L140.287 136.58L140.192 136.457L140.04 136.272C138.833 134.852 137.744 133.341 136.785 131.754C138.097 133.085 139.302 134.51 140.391 136.018L140.534 136.203L140.287 136.133C141.972 135.463 143.71 134.925 145.484 134.525Z"
          fill="black"
        />
      </g>
      <path
        d="M139.286 134.965C138.687 135.486 137.994 135.896 137.241 136.173C136.547 136.571 135.788 136.849 134.996 136.997C135.6 136.479 136.296 136.069 137.05 135.788C137.741 135.393 138.498 135.114 139.286 134.965Z"
        fill="#697AE2"
      />
      <g opacity="0.1">
        <path
          d="M139.284 134.965C138.685 135.486 137.992 135.896 137.239 136.173C136.545 136.571 135.786 136.849 134.994 136.997C135.599 136.479 136.294 136.069 137.048 135.788C137.739 135.393 138.496 135.114 139.284 134.965Z"
          fill="black"
        />
      </g>
      <path
        d="M143.86 139.806C143.373 139.226 142.996 138.567 142.746 137.859C142.221 136.712 141.871 135.727 142.03 135.742C142.518 136.323 142.898 136.982 143.152 137.69C143.523 138.346 143.763 139.064 143.86 139.806Z"
        fill="#697AE2"
      />
      <g opacity="0.1">
        <path
          d="M143.861 139.806C143.374 139.226 142.997 138.567 142.747 137.859C142.222 136.712 141.872 135.727 142.031 135.742C142.519 136.323 142.899 136.982 143.153 137.69C143.524 138.346 143.764 139.064 143.861 139.806Z"
          fill="black"
        />
      </g>
      <path
        d="M180.995 155.022L158.612 147.914L154.038 161.384L176.421 168.492L180.995 155.022Z"
        fill="#697AE2"
      />
      <path
        d="M180.975 155.038C180.657 155.215 180.321 155.362 179.973 155.476L177.187 156.531C174.799 157.4 171.544 158.586 167.875 159.887L167.724 159.94L167.628 159.817L167.254 159.34C164.818 156.261 162.622 153.39 161.054 151.32L159.183 148.865C158.945 148.571 158.734 148.257 158.555 147.926C158.847 148.181 159.113 148.462 159.351 148.765L161.301 151.143C162.932 153.16 165.161 155.969 167.604 159.086L167.978 159.556L167.732 159.486C171.393 158.193 174.696 157.054 177.083 156.254L179.917 155.322C180.26 155.196 180.614 155.1 180.975 155.038Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M180.975 155.038C180.657 155.215 180.321 155.362 179.973 155.476L177.187 156.531C174.799 157.4 171.544 158.586 167.875 159.887L167.724 159.94L167.628 159.817L167.254 159.34C164.818 156.261 162.622 153.39 161.054 151.32L159.183 148.865C158.945 148.571 158.734 148.257 158.555 147.926C158.847 148.181 159.113 148.462 159.351 148.765L161.301 151.143C162.932 153.16 165.161 155.969 167.604 159.086L167.978 159.556L167.732 159.486C171.393 158.193 174.696 157.054 177.083 156.254L179.917 155.322C180.26 155.196 180.614 155.1 180.975 155.038Z"
          fill="black"
        />
      </g>
      <path
        d="M165.057 156.18C163.316 157.231 161.503 158.165 159.629 158.974C157.818 159.903 155.943 160.71 154.018 161.39C155.751 160.33 157.563 159.393 159.438 158.589C161.247 157.654 163.125 156.849 165.057 156.18Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M165.055 156.191C163.315 157.243 161.501 158.177 159.627 158.985C157.816 159.914 155.941 160.722 154.016 161.402C155.749 160.342 157.561 159.405 159.436 158.6C161.245 157.666 163.123 156.86 165.055 156.191Z"
          fill="black"
        />
      </g>
      <path
        d="M176.805 168.636C175.826 166.984 174.972 165.266 174.251 163.494C173.4 161.775 172.682 159.997 172.102 158.176C173.082 159.83 173.936 161.551 174.656 163.325C175.507 165.041 176.226 166.817 176.805 168.636Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M176.803 168.624C175.824 166.972 174.97 165.254 174.249 163.483C173.398 161.763 172.68 159.985 172.1 158.164C173.08 159.818 173.934 161.539 174.654 163.313C175.505 165.03 176.224 166.805 176.803 168.624Z"
          fill="black"
        />
      </g>
      <path
        d="M159.819 151.244C159.262 151.639 158.596 151.866 157.906 151.895C157.215 151.925 156.532 151.755 155.941 151.409C155.35 151.063 154.879 150.555 154.586 149.95C154.294 149.344 154.194 148.669 154.298 148.008C154.403 147.348 154.707 146.732 155.174 146.239C155.64 145.746 156.247 145.398 156.918 145.238C157.589 145.079 158.293 145.115 158.943 145.343C159.593 145.571 160.158 145.98 160.567 146.518C160.839 146.876 161.035 147.282 161.145 147.713C161.254 148.144 161.275 148.592 161.206 149.031C161.136 149.47 160.978 149.891 160.74 150.271C160.502 150.651 160.189 150.981 159.819 151.244Z"
        fill="#263238"
      />
      <path
        d="M159.978 147.892C159.239 148.424 158.453 148.892 157.63 149.293L157.073 149.593L156.97 149.647L156.914 149.555C156.537 148.977 156.218 148.366 155.959 147.73C156.402 148.264 156.789 148.839 157.113 149.447L156.962 149.401L157.519 149.093C158.301 148.624 159.124 148.222 159.978 147.892Z"
        fill="white"
      />
      <path
        d="M166.619 136.877L154.891 133.152L152.494 140.209L164.223 143.934L166.619 136.877Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M166.619 136.877L154.891 133.152L152.494 140.209L164.223 143.934L166.619 136.877Z"
          fill="black"
        />
      </g>
      <path
        d="M166.593 136.878C166.429 136.972 166.256 137.05 166.075 137.108L164.611 137.663C163.377 138.117 161.658 138.74 159.732 139.418H159.652L159.605 139.348L159.414 139.102C158.132 137.47 157.026 135.985 156.158 134.9L155.203 133.645C155.08 133.49 154.971 133.325 154.877 133.152C155.024 133.285 155.158 133.432 155.275 133.591L156.302 134.838C157.153 135.892 158.323 137.362 159.597 138.994L159.796 139.248H159.668C161.586 138.571 163.314 137.971 164.571 137.555L166.052 137.07C166.224 136.986 166.405 136.922 166.593 136.878Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M166.593 136.889C166.429 136.984 166.256 137.061 166.075 137.12L164.611 137.674C163.377 138.128 161.658 138.752 159.732 139.429H159.652L159.605 139.36L159.414 139.114C158.132 137.482 157.026 135.996 156.158 134.911L155.203 133.657C155.08 133.502 154.971 133.337 154.877 133.164C155.024 133.297 155.158 133.444 155.275 133.603L156.302 134.85C157.153 135.904 158.323 137.374 159.597 139.006L159.796 139.26H159.668C161.586 138.583 163.314 137.982 164.571 137.567L166.052 137.082C166.224 136.998 166.405 136.934 166.593 136.889Z"
          fill="#697AE2"
        />
      </g>
      <path
        d="M158.26 137.48C157.348 138.029 156.398 138.517 155.418 138.943C154.468 139.43 153.484 139.855 152.474 140.213C153.382 139.656 154.332 139.165 155.315 138.743C156.264 138.254 157.248 137.832 158.26 137.48Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M158.259 137.48C157.347 138.029 156.397 138.517 155.418 138.943C154.467 139.43 153.483 139.855 152.473 140.213C153.381 139.656 154.331 139.165 155.314 138.743C156.263 138.254 157.247 137.832 158.259 137.48Z"
          fill="#697AE2"
        />
      </g>
      <path
        d="M164.411 143.996C163.897 143.131 163.45 142.23 163.074 141.302C162.631 140.399 162.254 139.468 161.943 138.516C162.909 140.279 163.734 142.111 164.411 143.996Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M164.411 143.996C163.897 143.131 163.45 142.23 163.074 141.302C162.631 140.399 162.254 139.468 161.943 138.516C162.909 140.279 163.734 142.111 164.411 143.996Z"
          fill="#697AE2"
        />
      </g>
      <path
        d="M72.4285 48.544C71.6192 51.6244 69.8834 54.4037 67.4408 56.5302C64.9981 58.6568 61.9583 60.0352 58.7056 60.4911C55.4529 60.947 52.1335 60.46 49.167 59.0917C46.2005 57.7233 43.7202 55.535 42.0397 52.8036C40.3592 50.0721 39.554 46.9201 39.7259 43.7461C39.8977 40.5721 41.039 37.5186 43.0053 34.9718C44.9716 32.425 47.6747 30.4992 50.7727 29.438C53.8707 28.3767 57.2246 28.2276 60.4103 29.0096C64.6818 30.0602 68.3472 32.708 70.6009 36.371C72.8545 40.034 73.5118 44.4124 72.4285 48.544Z"
        fill="#263238"
      />
      <path
        d="M62.4559 35.8721C62.7503 35.9876 60.864 41.3676 58.2375 47.8945C57.8475 48.8412 57.4416 49.7494 57.1153 50.6422L56.9163 51.1271L56.4149 50.9424C51.0266 48.9335 47.0869 47.2787 47.1824 47.0401C47.2779 46.8015 51.3688 48.0484 56.7969 49.9495L56.0965 50.2574C56.4467 49.3645 56.8129 48.4486 57.1869 47.5019C59.8055 40.9674 62.1693 35.7875 62.4559 35.8721Z"
        fill="white"
      />
      <path
        d="M276.699 275.993C279.676 274.641 283.024 274.247 286.25 274.87C287.985 275.147 289.792 275.639 290.977 276.902C292.163 278.164 292.378 280.381 291.025 281.466C290.149 282.033 289.098 282.291 288.048 282.197C285.517 282.197 282.716 281.735 280.694 283.205C279.54 284.044 278.776 285.437 277.399 285.853C276.71 286.005 275.989 285.937 275.344 285.659C274.698 285.382 274.163 284.91 273.818 284.314C273.162 283.089 273.016 281.667 273.412 280.342C273.575 279.421 273.957 278.549 274.527 277.794C275.097 277.04 275.841 276.423 276.699 275.993Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M276.699 275.986C279.676 274.633 283.024 274.239 286.25 274.862C287.985 275.139 289.792 275.632 290.977 276.894C292.163 278.156 292.378 280.373 291.025 281.458C290.149 282.025 289.098 282.283 288.048 282.189C285.517 282.189 282.716 281.727 280.694 283.197C279.54 284.036 278.776 285.429 277.399 285.845C276.71 285.997 275.989 285.929 275.344 285.652C274.698 285.374 274.163 284.902 273.818 284.306C273.162 283.081 273.016 281.66 273.412 280.334C273.575 279.413 273.957 278.541 274.527 277.787C275.097 277.032 275.841 276.416 276.699 275.986Z"
          fill="white"
        />
      </g>
      <path
        d="M270.038 263.554C271.821 266.01 275.275 265.194 276.875 263.693C278.474 262.192 279.159 260.022 279.772 257.951C281.18 253.218 282.597 248.253 281.555 243.435C281.297 241.975 280.634 240.61 279.636 239.487C279.129 238.934 278.48 238.52 277.755 238.287C277.03 238.053 276.254 238.008 275.506 238.155C273.763 238.602 272.68 240.256 271.9 241.826C269.595 246.463 268.385 251.537 268.358 256.681C268.358 259.075 268.645 261.584 270.038 263.562"
        fill="#697AE2"
      />
      <path
        d="M265.158 267.08C267.084 264.863 267.45 261.63 266.591 258.852C265.615 256.095 263.875 253.651 261.561 251.786C258.925 249.535 255.862 247.8 252.543 246.676C251.256 246.173 249.871 245.944 248.484 246.006C247.791 246.046 247.119 246.248 246.526 246.596C245.932 246.943 245.435 247.425 245.077 247.999C244.13 249.747 245.038 251.848 246.025 253.603C247.76 256.657 249.74 259.575 251.946 262.331C253.721 264.54 255.758 266.726 258.465 267.719C261.171 268.711 263.98 268.604 265.413 266.787"
        fill="#697AE2"
      />
      <g opacity="0.1">
        <path
          d="M265.158 267.068C267.084 264.851 267.45 261.619 266.591 258.84C265.615 256.084 263.875 253.639 261.561 251.775C258.925 249.523 255.862 247.788 252.543 246.664C251.256 246.161 249.871 245.932 248.484 245.994C247.791 246.034 247.119 246.237 246.526 246.584C245.932 246.931 245.435 247.413 245.077 247.988C244.13 249.735 245.038 251.836 246.025 253.591C247.76 256.645 249.74 259.564 251.946 262.319C253.721 264.528 255.758 266.714 258.465 267.707C261.171 268.7 263.98 268.592 265.413 266.776"
          fill="black"
        />
      </g>
      <path
        d="M269.917 300.605C270.01 300.239 270.063 299.865 270.076 299.489C270.164 298.673 270.26 297.657 270.387 296.41C270.543 294.911 270.779 293.421 271.095 291.946C271.507 290.148 272.068 288.384 272.775 286.674C273.465 284.964 274.403 283.357 275.56 281.902C276.5 280.715 277.702 279.748 279.078 279.069C280.011 278.625 281.01 278.324 282.039 278.177C282.425 278.164 282.81 278.112 283.185 278.023C282.799 277.977 282.409 277.977 282.023 278.023C280.952 278.1 279.904 278.361 278.927 278.792C277.481 279.457 276.212 280.434 275.218 281.648C274.012 283.123 273.039 284.762 272.329 286.512C271.614 288.258 271.058 290.061 270.665 291.9C270.366 293.392 270.161 294.901 270.053 296.418C269.949 297.711 269.909 298.727 269.893 299.497C269.863 299.866 269.871 300.237 269.917 300.605Z"
        fill="#263238"
      />
      <path
        d="M270.132 298.247C270.156 298.076 270.156 297.903 270.132 297.731V296.254C270.132 294.968 270.132 293.106 270.196 290.812C270.315 286.194 270.721 279.875 271.517 272.909C272.313 265.944 273.364 259.671 274.327 255.207C274.55 254.083 274.78 253.067 274.995 252.182C275.21 251.297 275.377 250.519 275.545 249.873C275.712 249.226 275.807 248.811 275.903 248.434C275.957 248.266 275.989 248.093 275.998 247.918C275.917 248.072 275.856 248.235 275.815 248.403L275.369 249.827C275.17 250.443 274.971 251.204 274.741 252.09C274.51 252.975 274.247 253.983 273.992 255.114C272.694 260.971 271.719 266.891 271.071 272.848C270.276 279.829 269.925 286.186 269.894 290.789C269.894 293.098 269.894 294.953 269.973 296.238C270.01 296.839 270.042 297.331 270.069 297.716C270.072 297.895 270.093 298.073 270.132 298.247Z"
        fill="#263238"
      />
      <path
        d="M270.165 285.992C270.208 285.479 270.208 284.965 270.165 284.452C270.076 283.049 269.911 281.652 269.671 280.265C268.88 275.681 267.523 271.206 265.628 266.934C263.799 262.617 261.172 258.658 257.876 255.251C257.306 254.666 256.697 254.119 256.053 253.611C255.775 253.38 255.528 253.157 255.258 252.972L254.557 252.503C254.129 252.191 253.676 251.91 253.204 251.664C254.755 252.846 256.231 254.118 257.621 255.474C260.808 258.909 263.365 262.845 265.183 267.111C266.227 269.439 267.113 271.83 267.833 274.269C268.525 276.509 268.987 278.572 269.337 280.311C269.687 282.051 269.855 283.475 269.974 284.46C270 284.974 270.063 285.486 270.165 285.992Z"
        fill="#263238"
      />
      <path
        d="M149.113 184.523H182.438L195.634 148.871H193.788L180.703 182.783L149.113 182.96V184.523Z"
        fill="#455A64"
      />
      <path
        d="M134.389 189.042L135.622 300.715H139.013L137.636 188.219L134.389 189.042Z"
        fill="#263238"
      />
      <path
        d="M205.718 187.953C205.265 190.085 208.774 300.965 208.774 300.965H211.958L209.371 187.953H205.718Z"
        fill="#263238"
      />
      <path
        d="M244.678 184.535H102.982V190.185H114.674L107.065 300.348L110.392 300.525L118.009 190.185H232.755L238.319 300.572H241.654L236.09 190.185H244.678V184.535Z"
        fill="#263238"
      />
      <path
        d="M164.753 296.499V288.033L148.349 287.871L148.166 300.263L149.185 300.332C153.706 300.625 172.202 301.487 175.235 300.632C178.61 299.678 164.753 296.499 164.753 296.499Z"
        fill="#697AE2"
      />
      <path
        d="M154.105 300.193C153.88 298.853 153.148 297.641 152.052 296.791C150.976 295.948 149.614 295.523 148.231 295.598L148.184 300.032L154.105 300.193Z"
        fill="#F5F5F5"
      />
      <path
        d="M171.503 298.277C171.503 298.277 176.374 299.694 175.753 300.463C175.133 301.233 156.413 301.04 148.159 300.255V299.901L169.999 300.309C169.999 300.309 170.548 298.308 171.503 298.277Z"
        fill="#F5F5F5"
      />
      <path
        d="M148.216 299.895L149.012 299.956L151.989 300.11C154.504 300.225 157.982 300.333 161.826 300.379C165.67 300.426 169.156 300.379 171.671 300.326L174.656 300.241L175.452 300.202H175.731C175.638 300.191 175.545 300.191 175.452 300.202H174.656H171.671C169.156 300.202 165.67 300.202 161.834 300.202C157.998 300.202 154.512 300.064 151.997 299.987L149.012 299.895H148.216Z"
        fill="#263238"
      />
      <path
        d="M170.023 300.545C170.159 300.068 170.351 299.608 170.596 299.175C170.893 298.773 171.237 298.406 171.623 298.082C171.131 298.288 170.715 298.634 170.43 299.074C170.146 299.514 170.007 300.027 170.031 300.545H170.023Z"
        fill="#263238"
      />
      <path
        d="M165.207 298.139C165.255 298.139 165.494 297.893 165.732 297.531C165.971 297.169 166.138 296.853 166.09 296.823C166.043 296.792 165.812 297.069 165.565 297.431C165.394 297.639 165.272 297.88 165.207 298.139Z"
        fill="#263238"
      />
      <path
        d="M163.886 297.473C163.926 297.511 164.189 297.35 164.475 297.103C164.762 296.857 164.961 296.634 164.921 296.596C164.881 296.557 164.626 296.719 164.34 296.957C164.053 297.196 163.854 297.404 163.886 297.473Z"
        fill="#263238"
      />
      <path
        d="M163.066 295.94C163.337 296.042 163.629 296.082 163.918 296.056C164.207 296.092 164.501 296.066 164.778 295.979C164.507 295.877 164.215 295.837 163.926 295.863C163.637 295.827 163.344 295.854 163.066 295.94Z"
        fill="#263238"
      />
      <path
        d="M162.787 294.887C163.082 295.042 163.406 295.136 163.741 295.164C164.075 295.191 164.411 295.152 164.729 295.048C164.081 295 163.434 294.946 162.787 294.887Z"
        fill="#263238"
      />
      <path
        d="M165.781 296.755C166.132 296.813 166.49 296.813 166.84 296.755C167.221 296.716 167.596 296.641 167.962 296.531C168.186 296.471 168.403 296.385 168.607 296.277C168.669 296.245 168.725 296.202 168.77 296.149C168.815 296.096 168.849 296.035 168.869 295.969C168.887 295.893 168.882 295.814 168.855 295.741C168.829 295.667 168.781 295.602 168.718 295.554C168.319 295.363 167.863 295.311 167.428 295.406C166.993 295.501 166.606 295.738 166.33 296.077C166.163 296.276 166.041 296.507 165.972 296.755C165.972 296.932 165.972 297.032 165.972 297.032C166.078 296.718 166.242 296.425 166.458 296.17C166.697 295.9 167.012 295.705 167.365 295.608C167.571 295.544 167.788 295.52 168.004 295.537C168.22 295.554 168.43 295.612 168.623 295.708C168.79 295.831 168.718 296.016 168.535 296.123C168.345 296.222 168.145 296.302 167.938 296.362C167.582 296.476 167.218 296.564 166.848 296.624C166.187 296.701 165.781 296.701 165.781 296.755Z"
        fill="#263238"
      />
      <path
        d="M166.052 296.81C166.185 296.598 166.233 296.346 166.187 296.102C166.158 295.827 166.063 295.564 165.909 295.332C165.821 295.179 165.695 295.05 165.542 294.957C165.389 294.864 165.214 294.81 165.033 294.801C164.926 294.821 164.831 294.882 164.77 294.97C164.708 295.058 164.686 295.166 164.707 295.27C164.728 295.431 164.785 295.586 164.874 295.724C165.015 295.949 165.18 296.158 165.368 296.348C165.526 296.533 165.732 296.677 165.964 296.763C165.964 296.763 165.758 296.579 165.471 296.255C165.305 296.066 165.161 295.859 165.041 295.64C164.898 295.424 164.802 295.032 165.041 294.993C165.28 294.955 165.574 295.224 165.734 295.432C165.875 295.631 165.972 295.857 166.02 296.094C166.06 296.33 166.07 296.571 166.052 296.81Z"
        fill="#263238"
      />
      <path
        d="M148.318 295.559C148.318 295.559 148.788 295.52 149.528 295.613C150.492 295.731 151.403 296.104 152.163 296.69C152.901 297.305 153.452 298.104 153.754 298.999C153.993 299.677 154.017 300.139 154.049 300.131C154.081 300.123 154.049 300.015 154.049 299.815C154.022 299.523 153.966 299.235 153.882 298.953C153.595 298.019 153.025 297.191 152.244 296.575C151.463 295.96 150.508 295.587 149.504 295.505C149.203 295.472 148.898 295.472 148.597 295.505C148.501 295.506 148.407 295.524 148.318 295.559Z"
        fill="#263238"
      />
      <path
        d="M150.093 289.012C149.902 291.177 149.849 293.352 149.934 295.523C150.06 294.442 150.118 293.355 150.109 292.267C150.166 291.183 150.161 290.096 150.093 289.012Z"
        fill="#263238"
      />
      <path
        d="M156.189 298.668C156.97 298.856 157.773 298.941 158.577 298.922C159.377 298.972 160.18 298.918 160.965 298.761C160.965 298.707 159.89 298.761 158.577 298.761C157.264 298.761 156.197 298.614 156.189 298.668Z"
        fill="#263238"
      />
      <path
        d="M152.976 298.623C152.976 298.662 153.063 298.846 153.167 299.085C153.27 299.323 153.334 299.531 153.389 299.531C153.445 299.531 153.477 299.277 153.35 299.008C153.222 298.738 153.015 298.585 152.976 298.623Z"
        fill="#263238"
      />
      <path
        d="M151.781 297.156C151.781 297.195 151.845 297.356 152.012 297.526C152.179 297.695 152.346 297.803 152.386 297.764C152.426 297.726 152.322 297.564 152.155 297.395C151.988 297.226 151.821 297.118 151.781 297.156Z"
        fill="#263238"
      />
      <path
        d="M150.053 296.478C150.053 296.532 150.284 296.532 150.523 296.616C150.762 296.701 150.937 296.832 150.977 296.793C151.017 296.755 150.889 296.539 150.587 296.439C150.284 296.339 150.038 296.432 150.053 296.478Z"
        fill="#263238"
      />
      <path
        d="M148.836 296.154C148.836 296.2 148.908 296.285 149.043 296.338C149.178 296.392 149.306 296.392 149.321 296.338C149.337 296.285 149.25 296.208 149.115 296.154C148.979 296.1 148.836 296.1 148.836 296.154Z"
        fill="#263238"
      />
      <path
        d="M197.005 287.958L193.407 280.223L178.389 286.603L183.49 297.964L184.446 297.625C188.696 296.086 205.935 289.52 208.323 287.535C211.021 285.318 197.005 287.958 197.005 287.958Z"
        fill="#697AE2"
      />
      <path
        d="M188.91 295.533C188.136 294.391 186.95 293.568 185.583 293.224C184.246 292.888 182.828 293.044 181.604 293.663L183.442 297.719L188.91 295.533Z"
        fill="#F5F5F5"
      />
      <path
        d="M203.953 286.875C203.953 286.875 208.991 286.221 208.729 287.175C208.466 288.13 191.346 295.395 183.49 297.951L183.371 297.62L203.444 289.299C203.444 289.299 203.094 287.283 203.953 286.875Z"
        fill="#F5F5F5"
      />
      <path
        d="M183.147 297.724L183.418 297.64L184.214 297.37L187 296.324C189.34 295.423 192.571 294.145 196.081 292.652C199.591 291.159 202.759 289.751 205.027 288.688L207.709 287.426L208.433 287.064C208.521 287.031 208.604 286.987 208.68 286.934L208.418 287.034L207.677 287.357L204.971 288.573C202.687 289.589 199.519 290.99 196.001 292.475C192.483 293.961 189.268 295.269 186.944 296.201L184.19 297.301L183.442 297.601L183.147 297.724Z"
        fill="#263238"
      />
      <path
        d="M203.571 289.543C203.487 289.054 203.466 288.558 203.507 288.065C203.612 287.581 203.77 287.109 203.977 286.656C203.586 287.02 203.348 287.511 203.308 288.034C203.202 288.551 203.296 289.088 203.571 289.543Z"
        fill="#263238"
      />
      <path
        d="M198.182 289.239C198.238 289.239 198.333 288.908 198.405 288.469C198.476 288.03 198.484 287.699 198.405 287.699C198.325 287.699 198.253 288.03 198.182 288.469C198.116 288.722 198.116 288.986 198.182 289.239Z"
        fill="#263238"
      />
      <path
        d="M196.655 289.136C196.702 289.136 196.87 288.897 197.029 288.566C197.188 288.235 197.268 287.943 197.22 287.919C197.172 287.896 197.005 288.15 196.846 288.489C196.687 288.828 196.591 289.112 196.655 289.136Z"
        fill="#263238"
      />
      <path
        d="M195.261 288.091C195.54 288.065 195.811 287.984 196.057 287.853C196.495 287.676 196.853 287.491 196.805 287.445C196.757 287.399 196.415 287.499 196.009 287.676C195.603 287.853 195.237 288.037 195.261 288.091Z"
        fill="#263238"
      />
      <path
        d="M194.561 287.241C194.896 287.294 195.239 287.248 195.547 287.11C195.874 287.032 196.168 286.86 196.391 286.617C196.077 286.695 195.77 286.801 195.476 286.933C195.161 287.007 194.855 287.11 194.561 287.241Z"
        fill="#263238"
      />
      <path
        d="M198.086 287.757C198.429 287.671 198.755 287.531 199.049 287.341C199.377 287.15 199.686 286.931 199.973 286.687C200.152 286.543 200.315 286.38 200.458 286.202C200.542 286.088 200.579 285.948 200.562 285.809C200.548 285.734 200.51 285.665 200.455 285.61C200.4 285.555 200.329 285.517 200.251 285.502C199.963 285.49 199.676 285.541 199.411 285.65C199.146 285.759 198.909 285.923 198.717 286.132C198.526 286.34 198.385 286.588 198.305 286.855C198.225 287.123 198.207 287.405 198.254 287.68C198.263 287.775 198.293 287.866 198.341 287.949C198.313 287.619 198.339 287.286 198.421 286.964C198.525 286.625 198.73 286.323 199.01 286.094C199.334 285.813 199.76 285.667 200.196 285.686C200.403 285.725 200.418 285.925 200.291 286.102C200.16 286.266 200.014 286.418 199.853 286.556C199.575 286.798 199.28 287.022 198.97 287.226C198.437 287.572 198.071 287.726 198.086 287.757Z"
        fill="#263238"
      />
      <path
        d="M198.357 287.701C198.382 287.457 198.317 287.212 198.174 287.009C198.031 286.777 197.835 286.579 197.601 286.431C197.456 286.328 197.287 286.262 197.109 286.239C196.931 286.217 196.75 286.238 196.582 286.301C196.493 286.361 196.432 286.453 196.413 286.557C196.393 286.661 196.417 286.768 196.479 286.855C196.566 286.995 196.686 287.114 196.829 287.201C197.051 287.354 197.292 287.481 197.545 287.578C197.771 287.682 198.02 287.73 198.27 287.717C198.27 287.717 197.999 287.632 197.601 287.447C197.367 287.344 197.145 287.215 196.941 287.062C196.718 286.924 196.471 286.608 196.694 286.462C196.828 286.424 196.968 286.416 197.106 286.437C197.244 286.458 197.375 286.509 197.49 286.585C197.708 286.708 197.896 286.876 198.039 287.078C198.163 287.277 198.269 287.485 198.357 287.701Z"
        fill="#263238"
      />
      <path
        d="M181.66 293.619C181.66 293.619 182.066 293.396 182.782 293.188C183.709 292.906 184.701 292.885 185.64 293.126C186.58 293.367 187.429 293.861 188.091 294.55C188.6 295.081 188.815 295.489 188.847 295.466C188.816 295.363 188.771 295.264 188.711 295.174C188.566 294.92 188.393 294.683 188.194 294.465C187.539 293.722 186.669 293.186 185.695 292.926C184.72 292.683 183.693 292.724 182.742 293.042C182.464 293.14 182.197 293.266 181.946 293.419C181.739 293.526 181.652 293.619 181.66 293.619Z"
        fill="#263238"
      />
      <path
        d="M180.474 286.91C180.811 287.96 181.218 288.989 181.691 289.989C182.104 291.001 182.574 291.99 183.1 292.952C182.754 291.916 182.345 290.901 181.874 289.912C181.466 288.887 180.998 287.885 180.474 286.91Z"
        fill="#263238"
      />
      <path
        d="M190.151 293.319C190.943 293.173 191.713 292.93 192.443 292.596C193.197 292.324 193.909 291.954 194.56 291.495C194.56 291.449 193.581 291.919 192.363 292.419C191.145 292.919 190.135 293.266 190.151 293.319Z"
        fill="#263238"
      />
      <path
        d="M187.207 294.563C187.207 294.617 187.382 294.733 187.581 294.91C187.78 295.087 187.923 295.256 187.971 295.225C188.019 295.194 187.971 294.964 187.716 294.763C187.462 294.563 187.223 294.517 187.207 294.563Z"
        fill="#263238"
      />
      <path
        d="M185.496 293.688C185.496 293.742 185.639 293.85 185.862 293.934C186.085 294.019 186.284 294.05 186.308 294.004C186.332 293.957 186.165 293.842 185.942 293.757C185.719 293.673 185.512 293.619 185.496 293.688Z"
        fill="#263238"
      />
      <path
        d="M183.632 293.75C183.632 293.796 183.863 293.711 184.117 293.696C184.372 293.681 184.579 293.696 184.603 293.696C184.627 293.696 184.412 293.496 184.102 293.527C183.791 293.557 183.592 293.711 183.632 293.75Z"
        fill="#263238"
      />
      <path
        d="M182.367 293.949C182.367 294.002 182.495 294.041 182.638 294.033C182.781 294.026 182.9 293.979 182.9 293.926C182.9 293.872 182.773 293.833 182.63 293.841C182.487 293.849 182.367 293.895 182.367 293.949Z"
        fill="#263238"
      />
      <path
        d="M48.1614 231.381L36.3183 145.8C36.2444 145.268 36.2945 144.726 36.4648 144.214C36.6351 143.703 36.9214 143.235 37.3027 142.844C37.6841 142.453 38.1509 142.149 38.669 141.955C39.1872 141.761 39.7436 141.681 40.2978 141.721L68.847 144.03C70.1205 144.134 71.3147 144.671 72.2169 145.546C73.1191 146.421 73.6708 147.578 73.7737 148.81L80.73 229.834L48.1614 231.381Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M48.1614 231.381L36.3183 145.8C36.2444 145.268 36.2945 144.726 36.4648 144.214C36.6351 143.703 36.9214 143.235 37.3027 142.844C37.6841 142.453 38.1509 142.149 38.669 141.955C39.1872 141.761 39.7436 141.681 40.2978 141.721L68.847 144.03C70.1205 144.134 71.3147 144.671 72.2169 145.546C73.1191 146.421 73.6708 147.578 73.7737 148.81L80.73 229.834L48.1614 231.381Z"
          fill="black"
        />
      </g>
      <path
        d="M75.6991 105.601C75.4603 102.338 74.5769 99.1591 74.1073 95.9265C73.6377 92.6938 73.5422 89.2919 74.8634 86.2824C75.6721 84.6047 76.8153 83.0979 78.2276 81.8484C79.6398 80.599 81.2933 79.6314 83.0931 79.0013C86.708 77.7609 90.568 77.3345 94.3791 77.7544C95.068 77.7746 95.7464 77.9236 96.3768 78.1931C96.688 78.3325 96.9565 78.5477 97.156 78.8175C97.3555 79.0873 97.4792 79.4026 97.5149 79.7325C97.5227 79.8963 97.4954 80.0598 97.4348 80.2129C97.3743 80.366 97.2818 80.5055 97.163 80.6225C97.0443 80.7395 96.9019 80.8315 96.7447 80.8929C96.5875 80.9543 96.4189 80.9838 96.2494 80.9794C97.7032 81.123 99.1364 81.4198 100.523 81.8645C101.087 82.0189 101.577 82.3593 101.908 82.8266C102.043 83.1192 102.096 83.4408 102.062 83.7593C102.029 84.0779 101.91 84.3825 101.717 84.643C101.322 85.1587 100.834 85.6018 100.277 85.9515C100.447 85.919 100.622 85.9194 100.793 85.9525C100.963 85.9856 101.125 86.0508 101.269 86.1445C101.413 86.2381 101.537 86.3583 101.633 86.4982C101.729 86.6381 101.795 86.795 101.829 86.9598C101.88 87.2962 101.857 87.6391 101.763 87.9666C101.668 88.2941 101.503 88.5987 101.28 88.8609C100.874 89.415 100.348 89.8691 99.9265 90.4002C98.2074 92.6092 98.5417 95.6802 98.9555 98.4202"
        fill="#263238"
      />
      <path
        d="M76.0104 124.718L92.0957 124.441C92.3106 122.64 92.621 115.636 92.621 115.636C92.621 115.636 99.3464 115.036 99.8399 108.601C100.333 102.167 100.126 87.3121 100.126 87.3121C96.4418 85.5133 92.3275 84.6983 88.2083 84.9513C84.0891 85.2044 80.1145 86.5162 76.6948 88.7514L75.6602 89.4287L76.0104 124.718Z"
        fill="#B78876"
      />
      <path
        d="M97.8978 98.4723C97.7625 98.3408 97.6706 98.1734 97.6335 97.9914C97.5965 97.8094 97.616 97.6209 97.6896 97.4496C97.7633 97.2783 97.8878 97.132 98.0473 97.029C98.2069 96.926 98.3944 96.8711 98.5862 96.8711C98.7781 96.8711 98.9656 96.926 99.1252 97.029C99.2847 97.132 99.4092 97.2783 99.4828 97.4496C99.5565 97.6209 99.576 97.8094 99.539 97.9914C99.5019 98.1734 99.41 98.3408 99.2747 98.4723C99.185 98.5609 99.0779 98.6314 98.9597 98.6795C98.8415 98.7276 98.7145 98.7524 98.5862 98.7524C98.458 98.7524 98.331 98.7276 98.2128 98.6795C98.0946 98.6314 97.9875 98.5609 97.8978 98.4723Z"
        fill="#263238"
      />
      <path
        d="M99.594 96.9137C99.4825 97.0215 98.8617 96.4981 97.9464 96.4365C97.0311 96.375 96.3546 96.7983 96.2352 96.6751C96.1158 96.552 96.3069 96.4288 96.6093 96.2287C97.0203 95.9834 97.501 95.87 97.9826 95.9045C98.4643 95.9391 98.9224 96.1199 99.2915 96.4211C99.5621 96.6597 99.6497 96.8675 99.594 96.9137Z"
        fill="#263238"
      />
      <path
        d="M88.9759 97.0548C89.1338 97.1614 89.2554 97.3109 89.3252 97.4845C89.395 97.658 89.4099 97.8477 89.3679 98.0295C89.3259 98.2112 89.229 98.3768 89.0895 98.5051C88.95 98.6335 88.7742 98.7188 88.5845 98.7503C88.3947 98.7818 88.1995 98.758 88.0237 98.682C87.848 98.6059 87.6995 98.4811 87.5973 98.3233C87.4951 98.1655 87.4437 97.9818 87.4497 97.7957C87.4557 97.6096 87.5187 97.4294 87.6309 97.278C87.7039 97.1777 87.7966 97.0924 87.9038 97.0269C88.011 96.9614 88.1305 96.917 88.2555 96.8962C88.3804 96.8755 88.5084 96.8788 88.632 96.906C88.7557 96.9332 88.8725 96.9838 88.9759 97.0548Z"
        fill="#263238"
      />
      <path
        d="M89.5818 97.0997C89.4703 97.1998 88.8495 96.6764 87.9342 96.6148C87.0189 96.5532 86.3424 96.9766 86.231 96.8611C86.1195 96.7457 86.2946 96.6071 86.6051 96.4147C87.0133 96.1654 87.4935 96.0495 87.975 96.0841C88.4565 96.1188 88.9137 96.3021 89.2793 96.6071C89.5499 96.838 89.6375 97.0535 89.5818 97.0997Z"
        fill="#263238"
      />
      <path
        d="M92.7963 104.638C93.2786 104.484 93.7847 104.411 94.2926 104.422C94.5393 104.422 94.7303 104.369 94.7542 104.245C94.7747 104.013 94.7307 103.779 94.6269 103.568C94.4279 102.968 94.221 102.36 94.014 101.721C93.1783 99.0963 92.6053 96.9335 92.7485 96.8951C92.8918 96.8566 93.6718 98.9501 94.5154 101.575C94.7144 102.214 94.8975 102.829 95.0885 103.43C95.2192 103.74 95.2416 104.083 95.1522 104.407C95.1123 104.494 95.0519 104.57 94.9758 104.631C94.8998 104.691 94.8103 104.733 94.7144 104.753C94.5795 104.768 94.4434 104.768 94.3085 104.753C93.8013 104.798 93.29 104.759 92.7963 104.638Z"
        fill="#263238"
      />
      <path
        d="M89.7479 105.44C88.8326 104.832 92.2948 106.98 94.1652 106.98C93.8228 107.424 93.3345 107.741 92.7805 107.881C92.2266 108.021 91.6399 107.975 91.1169 107.749C89.143 106.941 89.7479 105.44 89.7479 105.44Z"
        fill="white"
      />
      <path
        d="M92.6454 115.542C89.5661 115.418 86.5952 114.408 84.1133 112.641C84.1133 112.641 85.8722 117.397 92.5499 117.213L92.6454 115.542Z"
        fill="#A36957"
      />
      <path
        d="M75.445 94.2749L75.7873 102.626C75.7873 102.626 78.8197 101.733 79.0187 101.648C79.2176 101.564 78.963 91.4194 78.963 91.4194C78.963 91.4194 78.5411 87.571 81.1199 86.6859C83.8339 85.7392 85.0119 87.6403 88.8561 88.2252C101.535 90.2957 100.667 86.532 100.667 86.532C100.667 86.532 102.259 84.8771 99.0039 83.0607C95.7487 81.2443 87.6065 80.6285 83.1494 82.114C74.3944 85.0311 73.8691 87.9328 73.8691 90.4958C73.8691 94.1517 75.445 94.2749 75.445 94.2749Z"
        fill="#263238"
      />
      <path
        d="M76.9331 95.1388C76.7899 95.0849 71.9985 94.4845 72.4522 99.9569C72.9058 105.429 78.4692 103.182 78.4692 103.036C78.4692 102.889 76.9331 95.1388 76.9331 95.1388Z"
        fill="#B78876"
      />
      <path
        d="M76.5754 101.213C76.5754 101.213 76.4799 101.29 76.3207 101.374C76.0925 101.489 75.8272 101.514 75.5805 101.444C75.1945 101.252 74.8697 100.962 74.6411 100.606C74.4126 100.249 74.2888 99.8388 74.2832 99.4193C74.2281 98.9492 74.2825 98.4732 74.4423 98.0262C74.4848 97.8544 74.569 97.695 74.6881 97.5611C74.8072 97.4272 74.9576 97.3228 75.1268 97.2565C75.2379 97.2194 75.3588 97.2207 75.469 97.2599C75.5792 97.2992 75.672 97.3741 75.7317 97.472C75.8193 97.6182 75.7874 97.7337 75.8193 97.7414C75.8511 97.7491 75.9307 97.6336 75.867 97.4181C75.8279 97.283 75.7404 97.1658 75.6203 97.0872C75.4525 96.9915 75.2543 96.9586 75.0631 96.9948C74.8342 97.056 74.6253 97.173 74.4567 97.3348C74.288 97.4965 74.1652 97.6975 74.1001 97.9184C73.8995 98.4069 73.8257 98.9354 73.8852 99.4578C74.0126 100.558 74.6811 101.544 75.477 101.713C75.6328 101.744 75.7937 101.74 75.948 101.703C76.1022 101.665 76.2459 101.595 76.3684 101.497C76.5754 101.382 76.5913 101.251 76.5754 101.213Z"
        fill="#A36957"
      />
      <path
        d="M140.145 168.933C140.145 168.933 148.104 168.564 149.695 169.264C152.449 170.426 160.647 182.857 160.233 182.857C158.992 182.857 156.437 182.857 156.437 182.857L143.702 178.185L140.702 178.916L140.145 168.933Z"
        fill="#B78876"
      />
      <path
        d="M174.368 204.633C174.368 204.633 198.181 284.394 198.237 284.002C198.293 283.609 176.891 293.954 176.891 293.954L162.453 236.274L174.368 204.633Z"
        fill="#263238"
      />
      <path
        d="M176.423 294.429L198.979 283.784L198 280.867L175.269 291.527L176.423 294.429Z"
        fill="#455A64"
      />
      <path
        d="M173.461 265.091C173.342 265.091 173.095 264.622 172.896 263.983C172.697 263.344 172.633 262.782 172.753 262.751C172.872 262.721 173.119 263.213 173.318 263.86C173.517 264.506 173.572 265.053 173.461 265.091Z"
        fill="#455A64"
      />
      <path
        d="M174.934 269.764C174.814 269.764 174.552 269.302 174.353 268.663C174.154 268.024 174.074 267.47 174.185 267.432C174.297 267.393 174.56 267.894 174.766 268.532C174.973 269.171 175.045 269.71 174.934 269.764Z"
        fill="#455A64"
      />
      <path
        d="M176.469 274.381C176.35 274.42 176.087 273.927 175.872 273.288C175.657 272.65 175.577 272.103 175.689 272.065C175.8 272.026 176.071 272.519 176.286 273.158C176.501 273.796 176.58 274.381 176.469 274.381Z"
        fill="#455A64"
      />
      <path
        d="M178.077 279.006C177.958 279.045 177.687 278.56 177.465 277.921C177.242 277.282 177.146 276.736 177.266 276.697C177.385 276.659 177.656 277.144 177.879 277.783C178.101 278.422 178.189 279.006 178.077 279.006Z"
        fill="#455A64"
      />
      <path
        d="M179.749 283.624C179.638 283.624 179.351 283.178 179.12 282.546C178.89 281.915 178.786 281.369 178.905 281.33C179.025 281.292 179.295 281.769 179.534 282.408C179.773 283.047 179.876 283.562 179.749 283.624Z"
        fill="#455A64"
      />
      <path
        d="M181.468 288.163C181.348 288.163 181.062 287.724 180.823 287.093C180.584 286.462 180.473 285.916 180.584 285.877C180.696 285.839 180.99 286.316 181.229 286.947C181.468 287.578 181.603 288.125 181.468 288.163Z"
        fill="#455A64"
      />
      <path
        d="M180.218 290.087C180.022 289.831 179.861 289.552 179.741 289.256C179.462 288.702 179.08 287.893 178.642 286.885C177.759 284.869 176.668 282.028 175.57 278.865C174.472 275.702 173.548 272.808 172.896 270.714C172.593 269.729 172.339 268.898 172.1 268.228C171.984 267.928 171.91 267.615 171.877 267.297C172.033 267.577 172.161 267.871 172.259 268.174C172.482 268.752 172.784 269.583 173.15 270.622L175.944 278.727C177.034 281.89 178.077 284.738 178.889 286.777L179.86 289.186C180.012 289.473 180.132 289.775 180.218 290.087Z"
        fill="#455A64"
      />
      <path
        d="M63.2681 199.089C63.4671 194.864 60.9839 222.279 82.7758 223.003C104.568 223.726 149.091 223.411 149.091 223.411L143.878 292.936H170.023L175.483 218.97C175.483 218.97 178.269 197.542 163.107 194.81C153.62 193.093 94.6189 192.547 94.6189 192.547L63.2681 199.089Z"
        fill="#263238"
      />
      <path
        d="M143.28 293.082H170.811V288.941L143.28 288.98V293.082Z"
        fill="#455A64"
      />
      <path
        d="M100.215 219.736C100.307 219.725 100.401 219.725 100.493 219.736L101.289 219.69L104.473 219.552L116.292 219.113L156.159 217.789H156.414V218.035C156.056 220.868 155.753 223.862 155.499 226.902C155.148 231.251 154.814 235.492 154.488 239.586C154.162 243.681 153.692 247.622 153.286 251.378C152.49 258.874 151.742 265.632 151.121 271.32C150.5 277.008 149.983 281.564 149.625 284.759C149.442 286.298 149.299 287.56 149.195 288.415C149.147 288.815 149.108 289.13 149.076 289.361C149.066 289.47 149.047 289.578 149.02 289.685C149.008 289.577 149.008 289.469 149.02 289.361C149.02 289.123 149.068 288.807 149.1 288.407C149.179 287.545 149.299 286.313 149.45 284.743C149.768 281.549 150.246 276.954 150.811 271.289C151.376 265.624 152.1 258.844 152.88 251.332C153.262 247.576 153.676 243.635 154.05 239.548C154.424 235.461 154.711 231.212 155.053 226.871C155.308 223.793 155.61 220.829 155.976 217.981L156.199 218.22L116.324 219.398L104.505 219.713L101.321 219.775H100.525C100.42 219.777 100.316 219.764 100.215 219.736Z"
        fill="#455A64"
      />
      <path
        d="M102.73 216.722C102.73 216.837 102.173 216.945 101.472 216.96C100.772 216.976 100.215 216.899 100.215 216.783C100.215 216.668 100.772 216.552 101.464 216.537C102.157 216.522 102.73 216.606 102.73 216.722Z"
        fill="#455A64"
      />
      <path
        d="M107.766 216.614C107.766 216.73 107.209 216.837 106.509 216.853C105.808 216.868 105.251 216.791 105.243 216.676C105.235 216.56 105.808 216.452 106.501 216.429C107.193 216.406 107.766 216.499 107.766 216.614Z"
        fill="#455A64"
      />
      <path
        d="M112.804 216.495C112.804 216.619 112.247 216.719 111.547 216.742C110.846 216.765 110.289 216.672 110.281 216.557C110.273 216.442 110.846 216.334 111.539 216.318C112.231 216.303 112.804 216.38 112.804 216.495Z"
        fill="#455A64"
      />
      <path
        d="M117.843 216.386C117.843 216.509 117.286 216.617 116.586 216.632C115.885 216.648 115.328 216.563 115.32 216.448C115.312 216.332 115.885 216.224 116.578 216.209C117.27 216.194 117.843 216.271 117.843 216.386Z"
        fill="#455A64"
      />
      <path
        d="M122.881 216.273C122.881 216.388 122.323 216.496 121.623 216.511C120.923 216.527 120.365 216.442 120.358 216.326C120.35 216.211 120.923 216.103 121.615 216.088C122.307 216.072 122.881 216.149 122.881 216.273Z"
        fill="#455A64"
      />
      <path
        d="M127.92 216.163C127.92 216.279 127.362 216.386 126.662 216.402C125.962 216.417 125.405 216.332 125.397 216.217C125.389 216.102 125.962 215.994 126.654 215.978C127.347 215.963 127.912 216.048 127.92 216.163Z"
        fill="#455A64"
      />
      <path
        d="M132.958 216.042C132.958 216.157 132.401 216.265 131.7 216.281C131 216.296 130.443 216.211 130.435 216.096C130.427 215.98 130.992 215.873 131.692 215.857C132.393 215.842 132.918 215.927 132.958 216.042Z"
        fill="#455A64"
      />
      <path
        d="M137.996 215.933C137.996 216.048 137.439 216.156 136.738 216.171C136.038 216.187 135.481 216.11 135.473 215.987C135.465 215.863 136.03 215.763 136.73 215.748C137.431 215.733 137.988 215.817 137.996 215.933Z"
        fill="#455A64"
      />
      <path
        d="M143.035 215.812C143.035 215.927 142.478 216.035 141.777 216.05C141.077 216.066 140.52 215.989 140.512 215.873C140.504 215.758 141.069 215.642 141.769 215.627C142.47 215.611 143.027 215.696 143.035 215.812Z"
        fill="#455A64"
      />
      <path
        d="M148.041 215.702C148.041 215.818 147.476 215.925 146.783 215.941C146.091 215.956 145.526 215.879 145.518 215.764C145.51 215.648 146.075 215.533 146.775 215.517C147.476 215.502 148.041 215.587 148.041 215.702Z"
        fill="#455A64"
      />
      <path
        d="M153.109 215.585C153.109 215.701 152.544 215.808 151.852 215.824C151.159 215.839 150.594 215.762 150.586 215.647C150.578 215.531 151.143 215.424 151.844 215.408C152.544 215.393 153.101 215.47 153.109 215.585Z"
        fill="#455A64"
      />
      <path
        d="M158.148 215.46C158.148 215.583 157.583 215.691 156.89 215.707C156.198 215.722 155.625 215.637 155.625 215.522C155.625 215.406 156.182 215.299 156.883 215.283C157.583 215.268 158.14 215.345 158.148 215.46Z"
        fill="#455A64"
      />
      <path
        d="M158.141 219.885C158.013 219.885 157.974 219.324 158.045 218.654C158.117 217.984 158.26 217.453 158.379 217.461C158.499 217.469 158.547 218.023 158.475 218.693C158.403 219.362 158.26 219.901 158.141 219.885Z"
        fill="#455A64"
      />
      <path
        d="M157.646 224.737C157.526 224.737 157.479 224.175 157.55 223.506C157.622 222.836 157.773 222.305 157.893 222.313C158.012 222.32 158.052 222.874 157.988 223.544C157.924 224.214 157.765 224.752 157.646 224.737Z"
        fill="#455A64"
      />
      <path
        d="M157.154 229.585C157.034 229.585 156.986 229.023 157.058 228.353C157.13 227.684 157.281 227.153 157.4 227.16C157.52 227.168 157.559 227.73 157.496 228.399C157.432 229.069 157.273 229.6 157.154 229.585Z"
        fill="#455A64"
      />
      <path
        d="M156.659 234.425C156.539 234.425 156.5 233.863 156.563 233.193C156.627 232.523 156.786 231.992 156.906 232C157.025 232.008 157.065 232.57 157.001 233.239C156.937 233.909 156.794 234.44 156.659 234.425Z"
        fill="#455A64"
      />
      <path
        d="M156.167 239.288C156.047 239.288 156.007 238.719 156.071 238.049C156.135 237.379 156.294 236.848 156.413 236.864C156.533 236.879 156.581 237.425 156.509 238.095C156.437 238.765 156.286 239.296 156.167 239.288Z"
        fill="#455A64"
      />
      <path
        d="M155.673 244.14C155.554 244.14 155.514 243.57 155.585 242.9C155.657 242.231 155.8 241.7 155.92 241.715C156.039 241.731 156.087 242.277 156.015 242.947C155.944 243.616 155.8 244.147 155.673 244.14Z"
        fill="#455A64"
      />
      <path
        d="M155.203 248.979C155.076 248.979 155.036 248.418 155.108 247.748C155.179 247.078 155.323 246.54 155.45 246.555C155.577 246.57 155.609 247.117 155.537 247.786C155.466 248.456 155.307 248.979 155.203 248.979Z"
        fill="#455A64"
      />
      <path
        d="M154.694 253.831C154.574 253.831 154.526 253.269 154.598 252.6C154.67 251.93 154.821 251.391 154.94 251.407C155.06 251.422 155.1 251.968 155.036 252.638C154.972 253.308 154.813 253.839 154.694 253.831Z"
        fill="#455A64"
      />
      <path
        d="M154.2 258.682C154.08 258.682 154.041 258.121 154.104 257.451C154.168 256.781 154.327 256.25 154.447 256.258C154.566 256.266 154.606 256.82 154.542 257.489C154.478 258.159 154.319 258.698 154.2 258.682Z"
        fill="#455A64"
      />
      <path
        d="M153.708 263.534C153.588 263.534 153.548 262.972 153.612 262.302C153.676 261.633 153.835 261.102 153.954 261.109C154.074 261.117 154.114 261.671 154.05 262.341C153.986 263.011 153.827 263.549 153.708 263.534Z"
        fill="#455A64"
      />
      <path
        d="M153.214 268.386C153.094 268.386 153.054 267.824 153.118 267.154C153.182 266.484 153.341 265.953 153.46 265.961C153.58 265.969 153.627 266.523 153.556 267.193C153.484 267.862 153.333 268.401 153.214 268.386Z"
        fill="#455A64"
      />
      <path
        d="M152.729 273.225C152.601 273.225 152.561 272.663 152.633 271.994C152.705 271.324 152.848 270.793 152.967 270.801C153.087 270.809 153.134 271.37 153.063 272.04C152.991 272.71 152.816 273.241 152.729 273.225Z"
        fill="#455A64"
      />
      <path
        d="M152.234 278.077C152.114 278.077 152.067 277.515 152.138 276.845C152.21 276.176 152.353 275.645 152.48 275.652C152.608 275.66 152.64 276.222 152.568 276.892C152.496 277.561 152.353 278.092 152.234 278.077Z"
        fill="#455A64"
      />
      <path
        d="M151.741 282.94C151.622 282.94 151.574 282.371 151.646 281.701C151.718 281.032 151.869 280.501 151.988 280.516C152.108 280.531 152.147 281.078 152.084 281.747C152.02 282.417 151.861 282.948 151.741 282.94Z"
        fill="#455A64"
      />
      <path
        d="M151.223 287.78C151.104 287.78 151.064 287.218 151.128 286.549C151.191 285.879 151.351 285.34 151.47 285.356C151.589 285.371 151.629 285.918 151.566 286.587C151.502 287.257 151.367 287.788 151.223 287.78Z"
        fill="#455A64"
      />
      <path
        d="M150.985 290.353C150.865 290.353 150.77 290.3 150.778 290.261C150.786 290.223 150.881 290.192 151.001 290.207C151.12 290.223 151.216 290.261 151.216 290.3C151.216 290.338 151.104 290.361 150.985 290.353Z"
        fill="#455A64"
      />
      <path
        d="M152.656 220.172C152.199 220.866 151.62 221.479 150.945 221.981C150.365 222.583 149.684 223.088 148.932 223.474C149.969 222.175 151.228 221.058 152.656 220.172Z"
        fill="#263238"
      />
      <path
        d="M154.462 221.105C153.597 221.71 152.652 222.199 151.653 222.56C150.71 223.045 149.703 223.402 148.66 223.622C149.525 223.018 150.47 222.529 151.47 222.168C152.413 221.684 153.42 221.327 154.462 221.105Z"
        fill="#263238"
      />
      <path
        d="M101.296 165.938C101.296 165.938 98.6298 138.314 93.8942 126.223L94.8334 125.538L93.4246 120.773L75.7077 118.695L75.9783 122.413C73.3638 123.552 71.2278 125.517 69.9214 127.985C63.5542 141.024 66.7378 156.248 63.3233 184.456C63.3233 184.456 59.3438 197.687 63.4507 201.828L102.108 193.115L100.285 184.587C117.159 184.279 144.904 181.278 144.904 181.278L144.108 165.838C144.108 165.838 102.649 166.077 101.296 165.938Z"
        fill="#455A64"
      />
      <path
        d="M93.3928 120.75C93.9549 121.719 94.3855 122.754 94.6742 123.829C94.8473 124.249 94.965 124.689 95.0244 125.137C95.036 125.37 94.9565 125.598 94.8016 125.776C94.6739 125.922 94.5094 126.033 94.324 126.099C93.6555 126.299 93.2734 126.161 93.2734 126.099C93.2734 126.038 93.6873 126.03 94.1887 125.799C94.323 125.741 94.4364 125.646 94.5144 125.525C94.5924 125.404 94.6314 125.264 94.6265 125.122C94.5361 124.716 94.4137 124.317 94.2604 123.929C93.8352 122.908 93.5438 121.84 93.3928 120.75Z"
        fill="#E0E0E0"
      />
      <path
        d="M97.6186 141.309C97.6186 141.433 90.4554 141.525 81.7004 141.525C72.9454 141.525 65.7822 141.433 65.7822 141.309C65.7822 141.186 72.9454 141.102 81.7004 141.102C90.4554 141.102 97.6186 141.225 97.6186 141.309Z"
        fill="#263238"
      />
      <path
        d="M95.956 133.528C95.956 133.644 89.6365 133.744 81.8525 133.744C74.0685 133.744 67.749 133.644 67.749 133.528C67.749 133.413 74.0606 133.32 81.8525 133.32C89.6445 133.32 95.956 133.413 95.956 133.528Z"
        fill="#263238"
      />
      <path
        d="M93.6318 126.692C93.6318 126.808 88.4106 126.9 81.9638 126.9C75.5169 126.9 70.3037 126.808 70.3037 126.692C70.3037 126.577 75.5249 126.477 81.9638 126.477C88.4027 126.477 93.6318 126.607 93.6318 126.692Z"
        fill="#263238"
      />
      <path
        d="M93.4005 121.856C90.4268 122.074 87.4437 122.146 84.4625 122.072C81.4813 122.146 78.4981 122.074 75.5244 121.856C75.5244 121.741 79.504 121.648 84.4625 121.648C89.421 121.648 93.4005 121.741 93.4005 121.856Z"
        fill="#263238"
      />
      <path
        d="M98.7412 148.809C98.7412 148.925 91.2039 149.017 81.9077 149.017C72.6115 149.017 65.0742 148.925 65.0742 148.809C65.0742 148.694 72.6115 148.594 81.9077 148.594C91.2039 148.594 98.7412 148.686 98.7412 148.809Z"
        fill="#263238"
      />
      <path
        d="M100.111 156.321C100.111 156.436 92.2711 156.529 82.6008 156.529C72.9305 156.529 65.0908 156.436 65.0908 156.321C65.0908 156.206 72.9305 156.105 82.6008 156.105C92.2711 156.105 100.111 156.206 100.111 156.321Z"
        fill="#263238"
      />
      <path
        d="M100.97 163.243C100.97 163.366 92.931 163.458 83.022 163.458C73.1129 163.458 65.0742 163.366 65.0742 163.243C65.0742 163.12 73.1129 163.035 83.022 163.035C92.931 163.035 100.97 163.128 100.97 163.243Z"
        fill="#263238"
      />
      <path
        d="M140.145 170.829C140.145 170.944 123.192 171.037 102.283 171.037C81.3749 171.037 64.4141 170.944 64.4141 170.829C64.4141 170.713 81.3669 170.613 102.283 170.613C123.2 170.613 140.145 170.713 140.145 170.829Z"
        fill="#263238"
      />
      <path
        d="M140.439 176.899C140.439 177.022 123.358 177.115 102.291 177.115C81.2229 177.115 64.1348 177.022 64.1348 176.899C64.1348 176.776 81.215 176.691 102.291 176.691C123.366 176.691 140.439 176.784 140.439 176.899Z"
        fill="#263238"
      />
      <path
        d="M99.6803 184.376C99.6803 184.499 91.5222 184.591 81.454 184.591C71.3857 184.591 63.2197 184.499 63.2197 184.376C63.2197 184.253 71.3778 184.168 81.454 184.168C91.5302 184.168 99.6803 184.26 99.6803 184.376Z"
        fill="#263238"
      />
      <path
        d="M101.535 191.321C101.535 191.437 92.6603 191.537 81.7166 191.537C70.7728 191.537 61.8984 191.437 61.8984 191.321C61.8984 191.206 70.7728 191.113 81.7166 191.113C92.6603 191.113 101.535 191.206 101.535 191.321Z"
        fill="#263238"
      />
      <path
        d="M67.7873 193.154C67.66 193.154 67.5645 179.693 67.5645 163.091C67.5645 146.489 67.66 133.027 67.7873 133.027C67.9147 133.027 68.0022 146.489 68.0022 163.091C68.0022 179.693 67.9067 193.154 67.7873 193.154Z"
        fill="#263238"
      />
      <path
        d="M77.3477 192.426C77.2283 192.426 77.1328 175.97 77.1328 155.674C77.1328 135.377 77.2044 118.914 77.3477 118.914C77.491 118.914 77.5706 135.362 77.5706 155.666C77.5706 175.97 77.475 192.426 77.3477 192.426Z"
        fill="#263238"
      />
      <path
        d="M87.9083 191.314C87.7889 191.314 87.6934 175.451 87.6934 155.909C87.6934 136.367 87.7889 120.504 87.9083 120.504C88.0276 120.504 88.1311 136.367 88.1311 155.909C88.1311 175.451 88.0356 191.314 87.9083 191.314Z"
        fill="#263238"
      />
      <path
        d="M96.7833 193.14C96.6639 193.14 96.5684 180.687 96.5684 165.332C96.5684 149.977 96.6639 137.516 96.7833 137.516C96.9026 137.516 97.0061 149.969 97.0061 165.332C97.0061 180.694 96.9026 193.14 96.7833 193.14Z"
        fill="#263238"
      />
      <path
        d="M106.183 165.895C106.287 166.794 106.316 167.7 106.271 168.604C106.271 170.274 106.271 172.575 106.175 175.115C106.08 177.655 106.032 179.964 105.952 181.627C105.945 182.531 105.871 183.434 105.73 184.328C105.626 183.431 105.596 182.528 105.642 181.627C105.642 179.957 105.642 177.655 105.738 175.108C105.833 172.56 105.881 170.259 105.96 168.588C105.958 167.686 106.032 166.785 106.183 165.895Z"
        fill="#263238"
      />
      <path
        d="M116.203 166.223C116.302 167.066 116.331 167.915 116.291 168.763C116.291 170.302 116.291 172.48 116.211 174.858C116.131 177.237 116.068 179.4 115.996 180.962C115.999 181.808 115.924 182.653 115.773 183.486C115.674 182.649 115.645 181.804 115.686 180.962C115.686 179.423 115.686 177.237 115.773 174.851C115.861 172.465 115.909 170.31 115.988 168.747C115.981 167.901 116.053 167.056 116.203 166.223Z"
        fill="#263238"
      />
      <path
        d="M126.168 166.051C126.277 166.865 126.309 167.687 126.264 168.506C126.264 170.045 126.264 172.116 126.184 174.425C126.105 176.734 126.049 178.827 125.969 180.336C125.977 181.159 125.905 181.981 125.754 182.791C125.655 181.979 125.623 181.161 125.659 180.344C125.659 178.804 125.659 176.734 125.747 174.425C125.834 172.116 125.882 170.015 125.953 168.506C125.956 167.683 126.027 166.862 126.168 166.051Z"
        fill="#263238"
      />
      <path
        d="M136.174 165.785C136.283 166.573 136.315 167.37 136.269 168.163C136.269 169.634 136.269 171.665 136.19 173.905C136.11 176.145 136.062 178.177 135.983 179.639C135.985 180.436 135.915 181.232 135.776 182.018C135.665 181.23 135.631 180.433 135.672 179.639C135.672 178.169 135.672 176.137 135.752 173.898C135.831 171.658 135.887 169.618 135.959 168.156C135.956 167.361 136.028 166.567 136.174 165.785Z"
        fill="#263238"
      />
      <path
        d="M97.8262 169.381C98.5465 167.67 99.857 166.253 101.535 165.371C100.951 166.069 100.321 166.73 99.6488 167.349C99.0856 168.063 98.4769 168.741 97.8262 169.381Z"
        fill="#263238"
      />
      <path
        d="M100.109 169.566C99.9429 168.793 100.004 167.99 100.285 167.248C100.566 166.507 101.057 165.857 101.701 165.371C101.797 165.456 101.128 166.241 100.698 167.395C100.269 168.55 100.285 169.566 100.109 169.566Z"
        fill="#263238"
      />
      <path
        d="M140.876 181.187C140.43 178.746 140.127 176.281 139.969 173.806C139.666 171.341 139.507 168.861 139.491 166.379C139.947 168.822 140.253 171.289 140.407 173.768C140.709 176.23 140.866 178.708 140.876 181.187Z"
        fill="#263238"
      />
      <path
        d="M141.378 169.294C141.378 169.348 141.155 169.501 141.124 169.886C141.117 169.988 141.131 170.09 141.165 170.187C141.199 170.283 141.253 170.372 141.323 170.448C141.412 170.526 141.524 170.575 141.643 170.591C141.763 170.606 141.884 170.586 141.991 170.533C142.104 170.491 142.201 170.419 142.272 170.325C142.342 170.23 142.383 170.118 142.389 170.002C142.378 169.9 142.347 169.801 142.296 169.711C142.246 169.622 142.177 169.542 142.095 169.478C141.784 169.24 141.49 169.317 141.474 169.255C141.458 169.194 141.768 168.978 142.27 169.255C142.416 169.331 142.541 169.441 142.633 169.576C142.725 169.71 142.78 169.864 142.795 170.025C142.806 170.225 142.752 170.423 142.641 170.591C142.53 170.76 142.366 170.89 142.174 170.964C141.985 171.05 141.774 171.077 141.568 171.041C141.363 171.005 141.174 170.908 141.028 170.764C140.924 170.642 140.852 170.499 140.817 170.345C140.783 170.191 140.787 170.031 140.829 169.879C140.972 169.317 141.386 169.24 141.378 169.294Z"
        fill="#263238"
      />
      <path
        d="M101.248 165.861H101.447L102.028 165.908C102.545 165.962 103.309 166.008 104.312 166.038C106.318 166.115 109.262 166.154 113.011 166.123C120.501 166.077 131.206 165.808 144.052 165.353H144.235V165.531C144.489 170.326 144.76 175.536 145.031 181.063V181.24H144.863C142.826 181.609 140.764 181.763 138.655 181.971L132.224 182.51C127.855 182.856 123.358 183.179 118.781 183.495L101.956 184.588C97.077 184.288 92.3414 182.594 89.0304 179.485C87.4028 177.985 86.1248 176.166 85.2817 174.151C84.4904 172.248 83.8595 170.287 83.3954 168.286C81.4295 160.589 80.2117 153.577 79.2168 147.759C78.7393 144.849 78.3334 142.225 77.9911 139.931C77.6285 137.979 77.392 136.008 77.2828 134.028C77.2178 132.77 77.4921 131.518 78.0787 130.395C78.2798 130.011 78.5492 129.664 78.8746 129.371C78.5958 129.703 78.348 130.059 78.1344 130.433C77.5915 131.555 77.3506 132.792 77.434 134.028C77.5778 135.999 77.8436 137.96 78.2299 139.9C78.604 142.209 79.0258 144.811 79.5193 147.72C80.5221 153.524 81.7956 160.489 83.7774 168.194C84.2471 170.176 84.8779 172.119 85.6637 174.005C86.4924 175.97 87.7461 177.742 89.3408 179.2C92.5244 182.225 97.2123 183.88 102.004 184.18L118.821 183.071L132.256 182.094L138.687 181.563C140.78 181.355 142.882 181.201 144.832 180.847L144.672 181.055C144.402 175.552 144.147 170.318 143.876 165.515L144.075 165.692C131.237 166.146 120.532 166.362 113.035 166.346C109.286 166.346 106.341 166.277 104.336 166.162C103.333 166.108 102.561 166.046 102.044 165.969L101.462 165.9C101.389 165.897 101.317 165.884 101.248 165.861Z"
        fill="#263238"
      />
      <path
        d="M101.249 165.86C101.073 165.47 100.935 165.066 100.835 164.652C100.604 163.882 100.285 162.728 99.9035 161.311C99.1075 158.487 98.1922 154.554 97.1735 150.213C96.1547 145.872 95.2235 141.939 94.5231 139.099L93.7272 135.735C93.6029 135.328 93.5203 134.911 93.4805 134.488C93.6514 134.88 93.7898 135.284 93.8943 135.697C94.1411 136.466 94.4594 137.613 94.8494 139.029C95.6453 141.846 96.6163 145.772 97.6351 150.12C98.6539 154.469 99.4896 158.325 100.206 161.242C100.524 162.612 100.795 163.744 101.002 164.613C101.116 165.022 101.199 165.439 101.249 165.86Z"
        fill="#263238"
      />
      <path
        d="M93.448 121.078C93.4551 121.363 93.4257 121.647 93.3605 121.925C93.2809 122.471 93.1535 123.249 92.9784 124.234C92.9023 124.771 92.7906 125.303 92.6441 125.827C92.5955 126.001 92.5054 126.161 92.3809 126.294C92.2565 126.428 92.1013 126.532 91.9278 126.597C91.5783 126.686 91.2109 126.686 90.8613 126.597C89.5481 126.335 88.1154 125.996 86.6191 125.604C83.6345 124.834 80.9761 123.98 79.0421 123.403L76.7578 122.71C76.4806 122.642 76.2133 122.541 75.9619 122.41C76.2562 122.418 76.548 122.465 76.8295 122.548C77.3866 122.671 78.1825 122.856 79.1615 123.118C81.1194 123.618 83.7937 124.403 86.7704 125.196C88.2587 125.588 89.6754 125.966 90.9807 126.22C91.2606 126.308 91.559 126.326 91.8482 126.273C91.9669 126.232 92.0736 126.163 92.1594 126.073C92.2452 125.984 92.3077 125.875 92.3417 125.758C92.5168 125.265 92.5884 124.696 92.7078 124.218C92.9148 123.264 93.0978 122.494 93.2252 121.956C93.2552 121.654 93.3303 121.359 93.448 121.078Z"
        fill="#263238"
      />
      <path
        d="M79.5195 227.625L85.0829 300.644L87.407 300.121L86.5394 229.164L79.5195 227.625Z"
        fill="#263238"
      />
      <path
        d="M55.946 230.164L39.0488 300.559H42.1051L66.2928 230.164H55.946Z"
        fill="#263238"
      />
      <path
        d="M100.635 230.164L123.684 300.559H126.152L107.838 230.164H100.635Z"
        fill="#263238"
      />
      <path
        d="M103.723 234.895L52.0684 267.537V269.322L105.163 234.895H103.723Z"
        fill="#263238"
      />
      <path
        d="M59.8926 234.894L115.861 271.315V268.529L61.5321 233.301L59.8926 234.894Z"
        fill="#263238"
      />
      <path
        d="M48.2252 231.371L114.779 230.209C115.375 230.198 115.962 230.066 116.502 229.822C117.042 229.578 117.524 229.227 117.914 228.791C118.305 228.356 118.597 227.846 118.772 227.295C118.946 226.743 118.998 226.163 118.926 225.591C118.805 224.578 118.299 223.645 117.506 222.973C116.713 222.301 115.69 221.94 114.636 221.958L47.1348 223.004L48.2252 231.371Z"
        fill="#697AE2"
      />
      <g opacity="0.2">
        <path
          d="M48.2242 231.371L114.778 230.209C115.374 230.198 115.961 230.066 116.501 229.822C117.041 229.578 117.523 229.227 117.913 228.791C118.304 228.356 118.596 227.846 118.771 227.295C118.945 226.743 118.997 226.163 118.925 225.591C118.804 224.578 118.298 223.645 117.505 222.973C116.712 222.301 115.689 221.94 114.635 221.958L47.1338 223.004L48.2242 231.371Z"
          fill="black"
        />
      </g>
      <path
        d="M41.4438 181.628L48.1613 231.38L100.636 230.172L100.5 199.954C100.519 198.767 100.279 197.589 99.7957 196.497C99.3128 195.405 98.5977 194.424 97.697 193.617C96.7963 192.81 95.73 192.195 94.5675 191.812C93.405 191.429 92.1722 191.287 90.9493 191.395L50.939 194.89L45.6701 142.159L40.3534 141.721C39.7981 141.681 39.2407 141.762 38.722 141.958C38.2032 142.154 37.7361 142.459 37.3548 142.851C36.9735 143.243 36.6875 143.713 36.5178 144.226C36.3481 144.739 36.2989 145.282 36.3739 145.815L41.4438 181.628Z"
        fill="#697AE2"
      />
    </svg>
  );
};
