import Product1 from "../../../assests/l-d.jpg";
import Product2 from "../../../assests/assurance-pack.jpg";
import Product3 from "../../../assests/well-being.jpg";
import Product4 from "../../../assests/e-inspect.jpg";
export const productDetailData = [
  {
    id: 1,
    name: "Compliance Sheet L&D (Learning & Development)",
    overview:
      "The Learning & Development (L&D) module is designed to enhance the skills and knowledge of your team, ensuring continuous improvement and professional growth. This module provides a comprehensive platform to create, manage, and track training programs tailored to your organisation’s needs.",
    keyFeatures: [
      "Training",
      "Create Courses",
      "Record Keeping",
      "Upskill Team Members on the Job",
      "Manage Content Completion and Track Performance",
      "Increase Participation through Discussion and Knowledge Sharing",
    ],
    img: Product1,
  },
  {
    id: 2,
    name: "Assurance Pack",
    overview:
      "The Assurance Pack module ensures that your organisation adheres to the highest standards of Health, Environment, Safety, and Quality (HESQ). It facilitates the management of essential documents, worker contracts, risk assessments, and sustainability practices.",
    keyFeatures: [
      "HESQ",
      "Documents",
      "Worker Contract",
      "Risk Management",
      "Environment & Sustainability",
    ],
    transform: "ScaleX(-1)",
    img: Product2,
  },
  {
    id: 3,
    name: "Well Being",
    overview:
      "The Well Being module focuses on promoting the health, safety, and overall well-being of your employees. It offers tools to monitor employee activities, ensure safe working conditions, and facilitate prompt reporting of incidents.",
    keyFeatures: [
      "Time Tracker",
      "Activity",
      "Employee Shift Activity",
      "Perform Audits",
      "Share Live Location",
      "Easy Access Forms for Lone Workers",
      "Reporting for Incidents",
    ],
    img: Product3,
  },
  {
    id: 4,
    name: "E-Inspect",
    overview:
      "The E-Inspect module streamlines the inspection process, making it easier to schedule, conduct, and track inspections. It includes tools for creating templates, managing assets, and analysing data to improve operational efficiency and compliance.",
    keyFeatures: [
      "Template Creation",
      "Scheduling",
      "Inspection",
      "Actions",
      "Assets",
      "Issues",
      "HeadsUp",
      "Sensors",
      "Analytics",
    ],
    transform: "ScaleX(-1)",
    img: Product4,
  },
];
