import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { ClipLoader } from "react-spinners";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CustomTextField from "../../components/custom-textfield/CustomTextField";
import axios from "axios";
import { AUTH_BASE_URL } from "../../config";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: "",
    val: false,
    variant: "",
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      values.companyShortCode = "CS";
      try {
        setLoading(true);
        const response = await axios?.put("/auth/signin", values, {
          baseURL: AUTH_BASE_URL,
        });
        localStorage?.setItem(
          "session",
          JSON?.stringify({
            authToken: response?.data?.data?.authToken,
            refreshToken: response?.data?.data?.refreshToken,
            user: response?.data?.data?.user,
          })
        );
        // localStorage.setItem("user", JSON.stringify(response?.data?.user));
        // localStorage.setItem("authToken", JSON.stringify(response?.data?.authToken));
        // localStorage.setItem(
        //   "refreshToken",
        //   JSON.stringify(response?.data?.refreshToken)
        // );
        setSnackbarMessage({
          message: "Sign in successful! Redirecting...",
          val: true,
          variant: "success",
        });
        setTimeout(() => {
          navigate("/status");
        }, 1000);
        resetForm();
      } catch (error) {
        setSnackbarMessage({
          message: error.response.data.message || "An error occurred!",
          val: true,
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              background: "white",
              borderRadius: "8px",
              p: "50px 30px",
              mb: "20px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "40px",
                fontWeight: "600",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
              }}
            >
              Sign In
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                fontFamily: "Red Hat Display",
                color: "#565666",
                py: "10px",
                mb: "20px",
              }}
            >
              Let’s Sign You In
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={12} sx={{ mb: "10px" }}>
                <Grid item xs={12}>
                  <CustomTextField
                    label="Email"
                    id="email"
                    require
                    placeholder="Enter your Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Password"
                  require
                  fieldType={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Enter your Password"
                  endIcon={
                    <IconButton
                      aria-label="toggle visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              disabled={loading}
              sx={{
                my: "20px",
                py: "12px",
                background: "#697AE2",
                fontWeight: "500",
                textTransform: "none",
                color: "#fff",
                "&:hover": {
                  background: "#5567d4",
                },
              }}
            >
              {loading ? <ClipLoader size={12} /> : "Sign In"}
            </Button>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <Link
                Link
                to="/forgot-password"
                style={{ textDecoration: "none", color: "#697AE2" }}
              >
                Forgot Password?
              </Link>
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Red Hat Display",
                color: "#565666",
                textAlign: "center",
                py: "10px",
              }}
            >
              Or
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Red Hat Display",
                color: "#565666",
                textAlign: "center",
                py: "10px",
                mb: "20px",
              }}
            >
              Not a registered user?
              <b
                style={{
                  fontWeight: "500",
                  fontFamily: "Red Hat Display",
                  color: "#697AE2",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              >
                <Link
                  Link
                  to="/get-started"
                  style={{ textDecoration: "none", color: "#697AE2" }}
                >
                  Register Now
                </Link>
              </b>
            </Typography>
          </Card>
        </form>
      </Container>
      <Snackbar
        open={snackbarMessage?.val}
        autoHideDuration={5000}
        onClose={() => setSnackbarMessage(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={snackbarMessage?.variant}
          onClose={() => setSnackbarMessage(false)}
        >
          {snackbarMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignInForm;
