import React from "react";
import NavBar from "../../components/navbar/NavBar";
import Footer from "../../components/footer/footer";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import ScrollToTop from "../../hooks/ScrollToTop";
import { cookiesPolicyData } from "./CookiesPolicy.data";

const CookiesPolicy = () => {
  return (
    <>
      <ScrollToTop />
      <NavBar />
      <Container>
        <Grid container justifyContent={"center"}>
          <Card
            sx={{
              width: "100%",
              background: "#F0F2FC",
              p: { md: "50px", xs: "10px" },
              borderRadius: "15px",
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              mb: "40px",
            }}
          >
            <Typography
              className={`item-center font-size-on-small`}
              sx={{
                fontSize: "48px",
                fontWeight: "700",
                fontFamily: "Red Hat Display",
                color: "#697AE2",
                textAlign: "center",
                pb: "20px",
              }}
            >
              Cookies Policy
            </Typography>
            <Typography
              textAlign="center"
              fontFamily="Red Hat Display"
              fontSize={16}
            >
              At Compliance Sheet, we are committed to protecting your privacy
              and ensuring that your personal information is handled in a safe
              and responsible manner. This Cookies Policy explains what cookies
              are, how we use them, and your choices regarding their usage.
              <br />
              <br />
              In some cases, we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.
            </Typography>
            <Box height={"100%"} width={"100%"} padding={"20px 0"}>
              {cookiesPolicyData?.map((item) => {
                return (
                  <div key={item?.id}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        fontFamily: "Red Hat Display",
                        color: "#565666",
                        py: "10px",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    {item?.detail && (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          fontFamily: "Red Hat Display",
                          color: "#565666",
                          mb: "10px",
                        }}
                      >
                        {item.detail}
                      </Typography>
                    )}
                    {item?.points &&
                      item?.points?.map((point, index) => (
                        <Typography
                          key={index}
                          sx={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            fontFamily: "Red Hat Display",
                            color: "#565666",
                            mb: "10px",
                          }}
                          component="li"
                        >
                          {point?.title}{" "}
                          <Typography
                            sx={{
                              fontWeight: "400",
                              fontFamily: "Red Hat Display",
                            }}
                            component={"span"}
                          >
                            {point?.detail}
                          </Typography>
                        </Typography>
                      ))}
                  </div>
                );
              })}
            </Box>
          </Card>
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default CookiesPolicy;
