import { Box, Typography } from "@mui/material";
import bgImage from "../../assests/bg-under-construction.svg"
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";


const Status = () => {

  return (
    <Box
      height={"60vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      paddingX={{ xs: 1, md: 5 }}
      sx={{
        backgroundImage: `url(${bgImage?.src})`,
        backgroundPositionY: "bottom",
      }}
    >
      <Typography my={1} variant="h2" color={'#333'} fontWeight='700' fontSize={'3rem'} textAlign={"center"}>
        Welcome to Compliance Sheet
      </Typography>
      <Typography
        variant="h6"
        color={'#333'}
        width={{ xs: "100%", md: "70%" }}
        textAlign={"center"}
        fontWeight='500'
        fontSize={'1rem'}
      >
        Thank you for choosing Compliance Sheet for your accounting
        needs. We’re excited to have you on board and can’t wait for you to
        start experiencing the full benefits of our system.
      </Typography>

      <Typography
        variant="h3"
        color={'#333'}
        width={{ xs: "100%", md: "70%" }}
        textAlign={"center"}
        fontWeight='600'
        fontSize={'2rem'}
        mt={4}
      >
        Your Verification is Under Progress.
      </Typography>
      <Typography
        variant="h6"
        color={'#333'}
        width={{ xs: "100%", md: "70%" }}
        textAlign={"center"}
        fontWeight='500'
        fontSize={'1rem'}
        mt={1}
      >
        Your account is currently undergoing verification. This process is
        essential to ensure the security and integrity of your data. Rest
        assured, our team is working diligently to complete this as quickly as
        possible.
      </Typography>
      <Typography
        variant="body1"
        color={'#333'}
        display={"flex"}
        alignItems={"center"}
        mt={2}
        fontSize={'1rem'}
      >
        Hang tight and talk to you real soon!
        <RocketLaunchIcon sx={{ color: "#898999" }} />
      </Typography>
    </Box>
  );
};

export default Status;